import { useMemo, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  useTheme,
  Paper,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import whatsappImg from "../../assets/svg/whatsapp-icon.svg";
import signalImg from "../../assets/svg/signal-icon.svg";
import skypeImg from "../../assets/svg/skype-icon.svg";
import telegramImg from "../../assets/svg/telegram-icon.svg";
import discordImg from "../../assets/svg/discord-icon.svg";
import AppTile from "./AppTile";
import { ExpandMore } from "@mui/icons-material";
interface ILink {
  label: string;
  value: string;
  name: string;
}
interface IMessagingProps {
  links: ILink[];
  handleClick: (link: ILink, cardName: string) => void;
}
const MessagingCard = (props: IMessagingProps) => {
  const { links, handleClick } = props;
  const [isExpanded, setIsExpanded] = useState(false);

  const theme = useTheme();
  const [activeSlide, setActiveSlide] = useState(0);
  const imgObj: any = {
    whatsApp: whatsappImg,
    signal: signalImg,
    discord: discordImg,
    skype: skypeImg,
    telegram: telegramImg,
  };
  const linkPerSlide = useMemo(() => 6, []);
  const startIndex = useMemo(
    () => activeSlide * linkPerSlide,
    [activeSlide, linkPerSlide]
  );
  const endIndex = useMemo(
    () => activeSlide * linkPerSlide + linkPerSlide,
    [activeSlide, linkPerSlide]
  );
  const dotsCount = useMemo(
    () => Math.ceil(links.length / linkPerSlide),
    [links.length, linkPerSlide]
  );
  return (
    <>
      <div
        className="accodianSm"
        style={{ width: "100%", margin: "15px 0px 0px 15px" }}
      >
        <Accordion
          onChange={() => {
            setIsExpanded(!isExpanded);
          }}
          sx={{ boxShadow: "none", width: "100%" }}
          expanded={isExpanded}
        >
          <AccordionSummary expandIcon={<ExpandMore />} sx={{ px: 2 }}>
            <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
              Messaging
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <Grid item xs={12} md={6}>
              <Paper elevation={0}>
                <Box
                  sx={{
                    pt: 4,
                    px: 4,
                    pb: 1,
                    [theme.breakpoints.up("md")]: {
                      height: "40vh",
                      overflowY: "auto",
                    },
                  }}
                >
                  <Grid container spacing={2}>
                    {links
                      .slice(startIndex, endIndex)
                      .map((link: ILink, index: number) => (
                        <Grid item xs={4} key={index}>
                          <AppTile
                            src={imgObj[link.label]}
                            link={link}
                            handleClick={(l) => handleClick(l, "messaging")}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Box>
                <Box
                  sx={{
                    [theme.breakpoints.up("md")]: {
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      gap: 1,
                      py: 1,
                      height: "4vh",
                    },
                    display: "none",
                  }}
                >
                  {links.length > 6 ? (
                    <>
                      {Array.from({ length: dotsCount }, (_, index) => (
                        <Button
                          sx={{
                            width: "0.7rem",
                            height: "0.7rem",
                            borderRadius: "50%",
                            cursor: "pointer",
                            p: 0,
                            minWidth: "0.7rem",
                            backgroundColor: `${
                              activeSlide === index ? "#FCD463" : "#F2F2F2"
                            }`,
                          }}
                          onClick={() => setActiveSlide(index)}
                        ></Button>
                      ))}
                    </>
                  ) : null}
                </Box>
              </Paper>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>

      <Grid className="accodianLg" item xs={12} md={6}>
        <Paper elevation={0}>
          <Box
            sx={{
              width: "100%",
              p: 1,
              borderBottom: `1px solid ${theme.palette.secondary.main}`,
            }}
          >
            <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
              Messaging
            </Typography>
          </Box>
          <Box
            sx={{
              pt: 4,
              px: 4,
              pb: 1,
              [theme.breakpoints.up("md")]: {
                height: "40vh",
                overflowY: "auto",
              },
            }}
          >
            <Grid container spacing={2}>
              {links
                .slice(startIndex, endIndex)
                .map((link: ILink, index: number) => (
                  <Grid item xs={4} key={index}>
                    <AppTile
                      src={imgObj[link.label]}
                      link={link}
                      handleClick={(l) => handleClick(l, "messaging")}
                    />
                  </Grid>
                ))}
            </Grid>
          </Box>
          <Box
            sx={{
              [theme.breakpoints.up("md")]: {
                width: "100%",
                display: "flex",
                justifyContent: "center",
                gap: 1,
                py: 1,
                height: "4vh",
              },
              display: "none",
            }}
          >
            {links.length > 6 ? (
              <>
                {Array.from({ length: dotsCount }, (_, index) => (
                  <Button
                    sx={{
                      width: "0.7rem",
                      height: "0.7rem",
                      borderRadius: "50%",
                      cursor: "pointer",
                      p: 0,
                      minWidth: "0.7rem",
                      backgroundColor: `${
                        activeSlide === index ? "#FCD463" : "#F2F2F2"
                      }`,
                    }}
                    onClick={() => setActiveSlide(index)}
                  ></Button>
                ))}
              </>
            ) : null}
          </Box>
        </Paper>
      </Grid>
    </>
  );
};

export default MessagingCard;
