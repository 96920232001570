import "./templateOne.scss";
import DefaultProfile from "../../../../assets/icons/defaultAvatar.svg";
import PhoneOutlineIcon from "../../../../assets/icons/phoneOutline.svg";
import EmailOutlineIcon from "../../../../assets/icons/mailOutline.svg";
import {
  IImageList,
  IUserDetails,
} from "../../../../pages/Home/homepage.interface";
import SocialMediaList from "../../SocialmediaList/SocialMediaList";
import DefaultCoverImage from "../../../../assets/svg/defaultCoverImage.svg";
import PoweredByImage from "../../../../assets/svg/poweredByImage.svg";
import DefaultCompanyLogo from "../../../../assets/svg/intouch-logo.svg";
import { Box } from "@mui/material";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";

interface IProps {
  details?: IUserDetails | any;
  images?: IImageList | any;
  isLoading?: boolean;
}

function TemplateOne({ details, images, isLoading = false }: IProps) {
  const personalDetails = details?.personal;
  const businessDetails = details?.business;
  const generalDetails = details?.general;
  const smartCardDetails = details?.smartCardSitting;

  return (
    <main className="templateOne">
      <div className="templateOne-cover">
        {images &&
        images.coverPhoto &&
        Object.values(images.coverPhoto).filter((val) => val === "").length ===
          0 ? (
          <img
            src={`data:${images.coverPhoto.imageType};base64,${images.coverPhoto.imageStream}`}
            className="templateOne-cover-image"
          />
        ) : (
          <>
            <img src={DefaultCoverImage} className="templateOne-cover-image" />
            <img src={PoweredByImage} className="templateOne-cover-poweredBy" />
          </>
        )}
      </div>
      {personalDetails && businessDetails && (
        <div className="templateOne-details">
          <div className="templateOne-details-profile">
            <div className="templateOne-details-profile-wrapper">
              {images &&
              images.profilePicture &&
              Object.values(images.profilePicture).filter((val) => val === "")
                .length === 0 ? (
                <img
                  src={`data:${images.profilePicture.imageType};base64,${images.profilePicture.imageStream}`}
                  alt="avatar"
                  className="templateOne-details-profile-wrapper-image"
                />
              ) : (
                <img
                  src={DefaultProfile}
                  alt="avatar"
                  className="templateOne-details-profile-wrapper-image"
                />
              )}
              {smartCardDetails && smartCardDetails.isProfileVerified && (
                <Box
                  sx={{
                    width: "1.5rem",
                    height: "1.5rem",
                    backgroundColor: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    position: "absolute",
                    bottom: "5px",
                    right: "5px",
                  }}
                >
                  <CheckCircleOutlinedIcon sx={{ color: "blue" }} />
                </Box>
              )}
            </div>
            <div className="templateOne-details-profile-companyLogo">
              {images &&
              images.companyLogo &&
              Object.values(images.companyLogo).filter((val) => val === "")
                .length === 0 ? (
                <img
                  src={`data:${images.companyLogo.imageType};base64,${images.companyLogo.imageStream}`}
                  alt="companyLogo"
                  className="templateOne-details-profile-companyLogo-image"
                />
              ) : (
                <img
                  src={DefaultCompanyLogo}
                  alt="companyLogo"
                  className="templateOne-details-profile-companyLogo-image"
                />
              )}
            </div>
          </div>
          <div className="templateOne-details-info">
            <div className="templateOne-details-info-container">
              <p className="templateOne-details-info-container-name">{`${personalDetails.firstName} ${personalDetails.lastName}`}</p>
              <p className="templateOne-details-info-container-role">
                {businessDetails.jobTitle} at {businessDetails.businessName}
              </p>
              <p className="templateOne-details-info-container-other">
                Piano, TX 75024
              </p>
            </div>
            <div className="templateOne-details-info-contact">
              <div className="templateOne-details-info-contact-phone">
                <img
                  src={PhoneOutlineIcon}
                  alt="phoneLogo"
                  className="templateOne-details-info-contact-phone-logo"
                />
                <p className="templateOne-details-info-contact-phone-value">
                  {generalDetails?.phones &&
                    Array.isArray(generalDetails?.phones) &&
                    generalDetails?.phones[0].value}
                </p>
              </div>
              <div className="templateOne-details-info-contact-email">
                <img
                  src={EmailOutlineIcon}
                  alt="emailLogo"
                  className="templateOne-details-info-contact-email-logo"
                />
                <p className="templateOne-details-info-contact-email-value">
                  {generalDetails?.emails &&
                    Array.isArray(generalDetails?.emails) &&
                    generalDetails?.emails[0].value}
                </p>
              </div>
            </div>
            <div className="templateOne-details-info-description">
              <p className="templateOne-details-info-description-value">
                {businessDetails?.about}
              </p>
            </div>
          </div>
        </div>
      )}
      <Box
        sx={{
          width: "100%",
          padding: "0 20px 0 45px",
          height: "11rem",
          overflowY: "auto",
        }}
        className="hide-scrollBar"
      >
        <SocialMediaList details={details} />
      </Box>
    </main>
  );
}

export default TemplateOne;
