import { Fragment, useEffect, useState, useMemo } from "react";
import { Backdrop, CircularProgress, Grid, Typography } from "@mui/material";
import "./homepage.scss";
import Navbar from "../../components/Home/Navbar";
import jsonData from "../../data/db.json";
import PersonalDetails from "../../components/Home/PersonalDetails";
import GeneralCard from "../../components/Home/GeneralCard";
import SocialCard from "../../components/Home/SocialCard";
import MessagingCard from "../../components/Home/MessagingCard";
import PaymentsCard from "../../components/Home/PaymentsCard";
import BusinessSection from "../../components/Home/BusinessSection";
import BasicInfo from "../../components/Home/BasicInfo";
import { useAppDispatch, useAppSelector } from "../../app/store-hooks";
import { IAppType, IImageList, IModifiedImageData } from "./homepage.interface";
import { useMsal } from "@azure/msal-react";
import {
  renderUserName,
  setDefaultMediaName,
  textCapitalize,
} from "../../utils/helperFunctions";
import UpdateDetailsCard from "../../components/Common/UpdateDetailsCard";
import ImageEditorModal from "../../components/Common/ImageEditorModal/ImageEditorModal";
import MobilePreviewScreen from "../../components/Home/MobilePreviewScreen";
import {
  FETCH_INITIAL_DATA,
  UPDATE_IMAGE_DETAILS,
  UPDATE_USER_DETAILS,
  VERIFY_USER,
} from "../../sagas/type";
import BuyTemplateModal from "../../components/Common/BuyTemplateModal/BuyTemplateModal";
import UpgradePlanModal from "../../components/Common/UpgradePlanModal/UpgradePlanModal";
import UnderDevelopmentPopup from "../../components/Common/UnderDevelopmentPopup/UnderDevelopmentPopup";
import { setAuthUser } from "../../state/userSlice";
import { useNavigate } from "react-router-dom";
import { callMsGraph } from "../../graph";
import { loginRequest } from "../../authConfig";

interface ILink {
  label: string;
  value: string;
  name: string;
}
export default function HomePage() {
  const [metaData] = useState(jsonData.data);
  const { instance, accounts } = useMsal();
  const [selectedApp, setSelectedApp] = useState<IAppType>();
  const [selectedAppName, setSelectedAppName] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState<string>("");
  const [showProfileEditor, setShowProfilleEditor] = useState({
    show: false,
    header: "",
  });
  const [editedImage, setEditedImage] = useState<IImageList>();
  const [modifiedImageUrl, setModifiedImageUrl] = useState<string>("");
  const [modifiedImageData, setModifiedImageData] = useState<
    IModifiedImageData | any
  >({});
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<null | string>(null);
  const [showUpgradePlanModal, setShowUpgradePlanModal] = useState(false);
  const [showDevelopmentModal, setShowDevelopmentModal] = useState(false);
  const [graphData, setGraphData] = useState<any>({});
  const userDetail: any = useAppSelector(
    (state) => state.userReducer.userDetails
  );
  const navigate = useNavigate();
  const userLoggedIn = localStorage.getItem("userID");
  const authUser: any = useAppSelector((state) => state.userReducer.authUser);
  const isLoading: boolean = useAppSelector(
    (state) => state.appLoadingReducer.isLoading
  );
  const imageList = useAppSelector((state) => state.userReducer.imageList);
  const addMoreAllowedFields = ["Emails", "Links", "Phones", "Urls"];
  let subscriptionType = userDetail?.smartCardSitting?.subscriptionType;

  const loginUser = useMemo(() => instance.getActiveAccount(), [instance]);
  const dispatch = useAppDispatch();
  //QuerriedUserId is only for development
  const QuerriedUserId = window?.location.href.split("code=")[1];
  const userIds: any = localStorage.getItem("userID");
  const userIdsParse: any = JSON.parse(userIds);

  useEffect(() => {
    if (accounts && accounts.length) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then(async (response) => {
          await callMsGraph(response.accessToken).then((response: any) => {
            setGraphData(response);
          });
        });
    }
  }, [accounts]);

  useEffect(() => {
    if (graphData && graphData.mail) {
      const mail = graphData.mail;
      dispatch({ type: VERIFY_USER, payload: { userEmail: mail } });
    }
  }, [graphData.mail]);

  useEffect(() => {
    if (userIdsParse) {
      dispatch({
        type: FETCH_INITIAL_DATA,
        payload: { userId: userIdsParse },
      });
    }
  }, []);

  const handleClose = () => setOpen(false);
  const handleClick = (link: ILink, cardName: string) => {
    setOpen(true);
    const clickedApp: IAppType = userDetail[cardName]
      ? userDetail[cardName][link.label]
      : null;
    setSelectedCard(textCapitalize(cardName));
    setSelectedAppName(textCapitalize(link.label));
    !clickedApp && addMoreAllowedFields.includes(link.label)
      ? setSelectedApp([
          { name: setDefaultMediaName(textCapitalize(link.label)), value: "" },
        ])
      : !clickedApp && !addMoreAllowedFields.includes(link.label)
      ? setSelectedApp({
          name: setDefaultMediaName(textCapitalize(link.label)),
          value: "",
        })
      : clickedApp && Array.isArray(clickedApp) && clickedApp[0].name === null
      ? setSelectedApp([
          { name: setDefaultMediaName(textCapitalize(link.label)), value: "" },
        ])
      : setSelectedApp(clickedApp);
  };
  const handleUpdate = () => {
    const payload = {
      setNode: `/${selectedCard}/${selectedAppName}`,
      [selectedCard]: {
        [selectedAppName]: selectedApp,
      },
      id: userDetail?.id,
    };

    if (userDetail && userDetail[`${selectedCard.toLowerCase()}`]) {
      payload.IsAddNode = false;
    } else {
      payload.IsAddNode = true;
    }
    dispatch({ type: UPDATE_USER_DETAILS, payload });
  };

  const handleTemplateUpdate = (template: string) => {
    const payload = {
      id: userDetail?.id,
      SetNode: "/SmartCardSitting/DefaultTemplateName",
      IsAddNode: true,
      SmartCardSitting: {
        DefaultTemplateName: template,
      },
    };
    dispatch({ type: UPDATE_USER_DETAILS, payload });
  };

  const handleAddNewFeild = () => {
    if (selectedApp && Array.isArray(selectedApp)) {
      setSelectedApp((prev: any) => [
        ...prev,
        { value: "", name: setDefaultMediaName(selectedAppName) },
      ]);
    }
  };
  const handleRemoveField = (index: number) => {
    if (selectedApp && Array.isArray(selectedApp)) {
      const tempFields = [...selectedApp];
      tempFields.splice(index, 1);
      setSelectedApp(tempFields);
    }
  };
  const uploadImages = () => {
    const imageExtension = modifiedImageData.imageType.split("/")[1];
    const payload = {
      ImagesSet: {
        ImageName: `${
          showProfileEditor.header === "profile image"
            ? "Profile_"
            : showProfileEditor.header === "company image"
            ? "Company_Logo_"
            : showProfileEditor.header === "cover image"
            ? "Cover_Image_"
            : ""
        }${userDetail?.id}.${imageExtension}`,
        ImageByte: modifiedImageData.imageStream,
      },
      userId: userDetail?.id,
    };
    dispatch({ type: UPDATE_IMAGE_DETAILS, payload });
  };
  useEffect(() => {
    if (loginUser) {
      const userEmail = loginUser?.username;
      if (userEmail) {
        dispatch({ type: VERIFY_USER, payload: { userEmail } });
      }
    }
  }, [loginUser]);

  //using for local testing need to remove and use the above useEffect
  //only for development
  // useEffect(() => {
  //   dispatch({
  //     type: FETCH_INITIAL_DATA,
  //     payload: { userId: QuerriedUserId },
  //   });
  // }, []);

  useEffect(() => {
    if (imageList && Object.keys(imageList).length > 0) {
      setEditedImage(imageList);
    }
  }, [imageList]);

  useEffect(() => {
    if (userDetail && Object.keys(userDetail).length > 0) {
      if (
        userDetail.smartCardSitting &&
        Object.keys(userDetail.smartCardSitting).length > 0
      ) {
        userDetail.smartCardSitting.defaultTemplateName
          ? setSelectedTemplate(userDetail.smartCardSitting.defaultTemplateName)
          : setSelectedTemplate(userDetail.smartCardSitting.templateList[0]);
      } else {
        setSelectedTemplate("Basic01");
      }
    }
  }, [userDetail]);
  // if (!authUser) {
  //   instance.logoutRedirect({ postLogoutRedirectUri: "/login" });
  // }
  return (
    <Fragment>
      <Navbar
        userDetail={userDetail}
        profileImage={imageList && imageList?.profilePicture}
        setShowDevelopmentModal={setShowDevelopmentModal}
        selectedTemplate={selectedTemplate}
      />
      <Grid container>
        <Grid item xs={12} md={8} sx={{ p: 2 }}>
          <Typography
            variant="h2"
            sx={{ fontWeight: 600, fontSize: "1.5rem", my: 2 }}
            gutterBottom
          >
            Hi{"  "}
            {renderUserName(
              userDetail?.personal?.firstName,
              userDetail?.personal?.lastName
            ) || loginUser?.name}
          </Typography>
          <Typography sx={{ fontWeight: 600, mb: 2 }}>
            Add your details
          </Typography>
          <BasicInfo
            setShowProfilleEditor={setShowProfilleEditor}
            imageList={imageList}
            subscriptionType={subscriptionType}
            setShowUpgradePlanModal={setShowUpgradePlanModal}
          />
          <PersonalDetails data={userDetail.personal} />
          <BusinessSection
            data={userDetail.business}
            subscriptionType={subscriptionType}
            setShowUpgradePlanModal={setShowUpgradePlanModal}
          />
          <Grid container spacing={2}>
            <GeneralCard links={metaData.general} handleClick={handleClick} />
            <SocialCard links={metaData.social} handleClick={handleClick} />
            <MessagingCard
              links={metaData.messaging}
              handleClick={handleClick}
            />
            <PaymentsCard links={metaData.payment} handleClick={handleClick} />
          </Grid>
        </Grid>
        <Grid
          item
          lg={4}
          md={12}
          xs={12}
          sx={{
            height: "calc(100vh - 4rem)",
            position: "sticky",
            top: "4rem",
            right: 0,
            backgroundColor: "#EDEDED",
            overflowY: "auto",
            paddingTop: "20px",
          }}
        >
          <MobilePreviewScreen
            userDetail={userDetail}
            imageList={imageList}
            setShowTemplateModal={setShowTemplateModal}
            isLoading={isLoading}
            selectedTemplate={selectedTemplate}
            setShowDevelopmentModal={setShowDevelopmentModal}
          />
        </Grid>
      </Grid>
      <UpdateDetailsCard
        open={open}
        selectedAppName={selectedAppName}
        selectedApp={selectedApp}
        handleAddNewFeild={handleAddNewFeild}
        handleClose={handleClose}
        handleRemoveField={handleRemoveField}
        handleUpdate={handleUpdate}
        setSelectedApp={setSelectedApp}
        addMoreAllowedFields={addMoreAllowedFields}
      />
      <ImageEditorModal
        showModal={showProfileEditor.show}
        onClose={() => {
          setShowProfilleEditor({ show: false, header: "" });
          setModifiedImageUrl("");
        }}
        modalHeader={showProfileEditor.header}
        imageDatas={editedImage}
        modifiedImageUrl={modifiedImageUrl}
        setModifiedImageUrl={setModifiedImageUrl}
        setModifiedImageData={setModifiedImageData}
        handleUpload={uploadImages}
      />
      <BuyTemplateModal
        handleTemplateUpdate={handleTemplateUpdate}
        showModal={showTemplateModal}
        handleCloseModal={() => setShowTemplateModal(false)}
        selectedTemplate={selectedTemplate}
        availableTemplates={userDetail?.smartCardSitting?.templateList}
      />
      <UpgradePlanModal
        showModal={showUpgradePlanModal}
        handleCloseModal={() => setShowUpgradePlanModal(false)}
        userName={`${userDetail?.personal?.firstName} ${userDetail?.personal?.lastName}`}
      />
      <UnderDevelopmentPopup
        showModal={showDevelopmentModal}
        handleCloseModal={() => setShowDevelopmentModal(false)}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 999 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
}
