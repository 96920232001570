import React, { useMemo } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import { Link, useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import {
  KeyboardArrowDownRounded,
  PersonOutlineOutlined,
  SettingsOutlined,
  LogoutRounded,
} from "@mui/icons-material";
import MainLogo from "../../assets/svg/intouchLogo.svg";
import { useMsal } from "@azure/msal-react";
import { renderUserName } from "../../utils/helperFunctions";
import { IUserDetails } from "../../pages/Home/homepage.interface";
import { useAppDispatch } from "../../app/store-hooks";
import { FETCH_INITIAL_DATA } from "../../sagas/type";

interface IProps {
  userDetail: IUserDetails;
  profileImage: {
    imageStream: string;
    imageType: string;
  };
  setShowDevelopmentModal: (e: any) => void;
  selectedTemplate?: string | null;
}

export default function Navbar({
  userDetail,
  profileImage,
  setShowDevelopmentModal,
  selectedTemplate,
}: IProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { instance } = useMsal();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loginUser = useMemo(() => instance.getActiveAccount(), [instance]);
  const availableTemplateList = userDetail?.smartCardSitting?.templateList;
  const userIds: any = localStorage.getItem("userID");
  const userIdsParse: any = JSON.parse(userIds);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = async () => {
    try {
      await instance.logoutPopup().then(() => {
        navigate("/login");
      });
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const handleProfileChange = async (value: any, index: number) => {
    localStorage.setItem("ProfileIndex", `${index}`);
    await dispatch({
      type: FETCH_INITIAL_DATA,
      payload: { userId: userIdsParse, navigate, idIndex: index },
    });
    handleClose();
  };

  return (
    <AppBar position="sticky" sx={{ top: 0, height: "4rem" }}>
      <Toolbar
        sx={{
          width: "100%",
          height: "100%",
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Link to="/">
          <img src={MainLogo} alt="" />
        </Link>

        <IconButton
          size="small"
          onClick={handleMenu}
          color="inherit"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            backgroundColor: "#4D4B4B",
            borderRadius: "0.5rem",
          }}
        >
          {profileImage && profileImage.imageStream !== "" ? (
            <div
              style={{
                width: "1.5rem",
                height: "1.5rem",
                borderRadius: "50%",
                backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={`data:${profileImage.imageType};base64,${profileImage.imageStream}`}
                alt="profileImage"
                style={{
                  width: "1.4rem",
                  height: "1.4rem",
                  borderRadius: "50%",
                }}
              />
            </div>
          ) : (
            <AccountCircle />
          )}
          <Typography variant="h6" sx={{ fontSize: "small" }}>
            Profile one
          </Typography>
          <KeyboardArrowDownRounded fontSize="medium" sx={{ color: "#fff" }} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={{
            "& .MuiMenuItem-root": {
              padding: 0,
              cursor: "default",
              "&:hover": { bgcolor: "transparent" },
            },
          }}
        >
          <Box
            sx={{
              padding: "10px 20px",
              display: "flex",
              alignItems: "center",
              gap: "15px",
              borderBottom: "1px solid #f2f4f7",
            }}
          >
            {profileImage && profileImage.imageStream !== "" ? (
              <img
                src={`data:${profileImage.imageType};base64,${profileImage.imageStream}`}
                alt="profileImage"
                style={{
                  width: "3rem",
                  height: "3rem",
                  borderRadius: "50%",
                }}
              />
            ) : (
              <Avatar sx={{ width: 24, height: 24 }} />
            )}
            <Box>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  color: "#344054",
                }}
              >
                {renderUserName(
                  userDetail?.personal?.firstName,
                  userDetail?.personal?.lastName
                ) || loginUser?.name}
              </Typography>
              <Typography
                sx={{ color: "#667085", fontSize: "14px", fontWeight: "400" }}
              >
                {userDetail.general.emails &&
                  Array.isArray(userDetail.general.emails) &&
                  userDetail.general.emails[0].value}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              padding: "10px 20px",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowDevelopmentModal(true);
              handleClose();
            }}
          >
            <PersonOutlineOutlined
              sx={{ width: "1.5rem", height: "1.5rem", color: "#BABABA" }}
            />
            <Typography
              sx={{ fontSize: "14px", fontWeight: 500, color: "#344054" }}
            >
              View Profile
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              padding: "10px 20px",
              borderBottom: "1px solid #f2f4f7",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowDevelopmentModal(true);
              handleClose();
            }}
          >
            <SettingsOutlined
              sx={{ width: "1.5rem", height: "1.5rem", color: "#BABABA" }}
            />
            <Typography
              sx={{ fontSize: "14px", fontWeight: 500, color: "#344054" }}
            >
              Settings
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              padding: "10px 20px",
              borderBottom: "1px solid #f2f4f7",
            }}
          >
            <FormControl>
              <RadioGroup
                defaultValue="female"
                name="profile"
                sx={{
                  "& .MuiTypography-root ": {
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#344054",
                  },
                }}
              >
                {userIdsParse && userIdsParse.length > 0 ? (
                  userIdsParse.map((item: string, index: number) => {
                    const isChecked = localStorage.getItem("ProfileIndex")
                      ? localStorage.getItem("ProfileIndex") == `${index}`
                      : index === 0;
                    return (
                      <FormControlLabel
                        value={item}
                        control={<Radio size="small" color="warning" />}
                        label={`Profile ${index + 1}`}
                        checked={isChecked}
                        onChange={(e) => {
                          handleProfileChange(item, index);
                        }}
                      />
                    );
                  })
                ) : (
                  <FormControlLabel
                    control={<Radio size="small" color="warning" />}
                    label={`Profile 1`}
                    checked={true}
                  />
                )}
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              padding: "10px 20px",
              cursor: "pointer",
            }}
            onClick={handleLogout}
          >
            <LogoutRounded
              sx={{ width: "1.5rem", height: "1.5rem", color: "#BABABA" }}
            />
            <Typography
              sx={{ fontSize: "14px", fontWeight: 500, color: "#344054" }}
            >
              Log out
            </Typography>
          </Box>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
