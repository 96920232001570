import { configureStore } from "@reduxjs/toolkit";
import snackbarReducer from "../state/snackbarSlice"
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas/rootSaga";
import userReducer from "../state/userSlice";
import loaderReducer from "../state/loaderSlice";

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
    reducer: {
        snackbar: snackbarReducer,
        userReducer: userReducer,
        appLoadingReducer: loaderReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
});

// then run the saga
sagaMiddleware.run(rootSaga)


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch