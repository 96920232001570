import { ThemeProvider } from "@emotion/react";
import { theme } from "./styles/themes";
import AppRoutes from "./routes/AppRoutes";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

function App() {
  const msalInstance = new PublicClientApplication(msalConfig);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <MsalProvider instance={msalInstance}>
          <AppRoutes />
        </MsalProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
