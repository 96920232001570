import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IUserDetails, IImageList } from "../pages/Home/homepage.interface";

const initialState: {
  userDetails: IUserDetails;
  imageList: IImageList;
  authUser: boolean;
} = {
  userDetails: {
    id: "",
    personal: {
      firstName: "",
      middleName: "",
      lastName: "",
      prefix: "",
      suffix: "",
      preferredName: "",
      address: {
        address1: "",
        address2: "",
        city: "",
        state: "",
        country: "",
        zip: "",
      },
    },
    general: {
      emails: null,
      phones: null,
      urls: null,
      links: null,
      about: null,
    },
    business: {
      about: "",
      jobTitle: "",
      businessName: "",
      department: "",
      address: {
        address1: "",
        address2: "",
        city: "",
        state: "",
        country: "",
        zip: "",
      },
    },
    social: {
      twitter: null,
      instagram: null,
      linkedIn: null,
      facebook: null,
      youTube: null,
      snapChat: null,
      tikTok: null,
      twitch: null,
      yelp: null,
    },
    payment: {
      payPal: null,
      venmo: null,
      cashApp: null,
    },
    messaging: {
      whatsApp: null,
      signal: null,
      discord: null,
      skype: null,
      telegram: null,
    },
    smartCardSitting: {
      defaultTemplateName: "",
      isProfileVerified: true,
      subscriptionType: "",
      userId: "",
      templateList: [],
    },
  },
  imageList: {
    companyLogo: {
      imageStream: "",
      imageType: "",
    },
    coverPhoto: {
      imageStream: "",
      imageType: "",
    },
    profilePicture: {
      imageStream: "",
      imageType: "",
    },
  },
  authUser: false,
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserDetails: (state, action: PayloadAction<{ user: IUserDetails }>) => {
      state.userDetails = action.payload.user;
    },
    setImageDatas: (state, action) => {
      state.imageList = action.payload.imagesList;
    },
    setAuthUser: (state, action) => {
      state.authUser = action.payload.authUser;
    },
  },
});

export const { setUserDetails, setImageDatas, setAuthUser } = userSlice.actions;
const userReducer = userSlice.reducer;
export default userReducer;
