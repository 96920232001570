import { useEffect, useRef, useState } from "react";
import {
  Accordion,
  Button,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { Form, Formik, FormikProps } from "formik";
import TextBox from "./TextBox";
import { IBusinessDetail } from "../../pages/Home/homepage.interface";
import { useAppSelector, useAppDispatch } from "../../app/store-hooks";
import * as Yup from "yup";
import ErrorText from "../Common/ErrorText/ErrorText";

interface IBusinessSection {
  data: {
    about: string;
    jobTitle: string;
    businessName: string;
    department: string;
    address: {
      address1: string;
      address2: string;
      city: string;
      state: string;
      country: string;
      zip: string;
    };
  };
  subscriptionType: string | undefined;
  setShowUpgradePlanModal: (e: any) => void;
}
const initialValues = {
  about: "",
  jobTitle: "",
  businessName: "",
  department: "",
  address: {
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    zip: "",
  },
};

const businessValidationSchema = Yup.object().shape({
  jobTitle: Yup.string().required("Field is required"),
  businessName: Yup.string().required("Field is required"),
  department: Yup.string().required("Field is required"),
});
const BusinessSection = (props: IBusinessSection) => {
  const userDetail = useAppSelector((state) => state.userReducer.userDetails);
  const dispatch = useAppDispatch();

  const formikRef = useRef<FormikProps<IBusinessDetail>>(null);
  const { data, subscriptionType, setShowUpgradePlanModal } = props;
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleSubmit = (values: IBusinessDetail) => {
    const payload = {
      setNode: "/Business",
      Business: {
        ...values,
      },
      id: userDetail?.id,
    };
    dispatch({ type: "updateUserdetails", payload });
  };

  useEffect(() => {
    if (data && Object.keys(data).length) {
      if (formikRef.current) {
        const { setFieldValue } = formikRef.current;
        const existingValues = Object.entries(data);
        existingValues.forEach(([key, value]) => {
          if (value !== null) {
            setFieldValue(key, value);
          }
        });
      }
    }
  }, [data]);

  useEffect(() => {
    if (subscriptionType === "standard") {
      setIsExpanded(false);
    }
  }, [subscriptionType]);

  return (
    <Box sx={{ my: 2 }}>
      <Accordion
        sx={{ boxShadow: "none" }}
        expanded={isExpanded}
        onChange={() => {
          if (subscriptionType === "standard") {
            setShowUpgradePlanModal(true);
          } else {
            setIsExpanded((prev) => !prev);
          }
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />} sx={{ px: 2 }}>
          <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
            Business
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            innerRef={formikRef}
            validationSchema={businessValidationSchema}
          >
            {({ handleChange, handleBlur, values, errors, touched }) => {
              const addressErrorObj = errors.address;
              return (
                <Form>
                  <Box
                    sx={{
                      borderTop: `1px solid ${theme.palette.secondary.main}`,
                      p: 2,
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          "& textarea": {
                            resize: "none",
                            width: "100%",
                            border: "1px solid #D8DAE5",
                            borderRadius: "4px",
                            p: 1,
                            outline: "none",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "#474D66",
                          }}
                        >
                          About
                        </Typography>
                        <textarea
                          style={{ position: "relative" }}
                          rows={3}
                          name={"about"}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values?.about}
                          maxLength={100}
                        />
                        <p
                          style={{
                            position: "absolute",
                            fontSize: "10px",
                            fontWeight: "600",
                            top: "38%",
                            right: "3%",
                          }}
                        >{`${values.about.length}/100`}</p>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextBox
                          label="Job Title"
                          name={"jobTitle"}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values?.jobTitle}
                          errorField={
                            touched.jobTitle && errors.jobTitle ? true : false
                          }
                          isRequired={true}
                        />
                        {touched.jobTitle && errors.jobTitle && (
                          <ErrorText value={errors.jobTitle} />
                        )}
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextBox
                          label="Business Name"
                          name={"businessName"}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values?.businessName}
                          errorField={
                            touched.businessName && errors.businessName
                              ? true
                              : false
                          }
                          isRequired={true}
                        />
                        {touched.businessName && errors.businessName && (
                          <ErrorText value={errors.businessName} />
                        )}
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextBox
                          label="Department"
                          name={"department"}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values?.department}
                          errorField={
                            touched.department && errors.department
                              ? true
                              : false
                          }
                          isRequired={true}
                        />
                        {touched.department && errors.department && (
                          <ErrorText value={errors.department} />
                        )}
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextBox
                          label="Address 1"
                          name={"address.address1"}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.address?.address1}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextBox
                          label="Address 2"
                          name={"address.address2"}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.address?.address2}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextBox
                          label="City"
                          name={"address.city"}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.address?.city}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextBox
                          label="State"
                          name={"address.state"}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.address?.state}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextBox
                          label="Country"
                          name={"address.country"}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.address?.country}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextBox
                          label="Zip"
                          name={"address.zip"}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.address?.zip}
                        />
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        width: "100%",
                        my: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        size="small"
                        type="submit"
                        sx={{
                          color: theme.palette.warning.contrastText,
                          bgcolor: theme.palette.warning.main,
                        }}
                      >
                        Save
                      </Button>
                    </Box>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default BusinessSection;
