import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface customTheme {
    intouchText?: {
      primary?: string;
      gray?: string;
    };
  }

  // fix the type error when referencing the Theme object in your styled component
  interface Theme extends customTheme {}
  // fix the type error when calling `createTheme()` with a custom theme option
  interface ThemeOptions extends customTheme {}
}

export const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "*::-webkit-scrollbar": {
          backgroundColor: "#FFF",
          width: "12px",
          height: "12px",
        },
        "*::-webkit-scrollbar-track": {
          background: "#f8f8f8",
          borderRadius: "8px",
        },
        "*::-webkit-scrollbar-thumb": {
          backgroundColor: "#babac0",
          borderRadius: "16px",
          border: "4px solid #fff",
          minHeight: "40px",
          "&:hover": {
            backgroundColor: "#babac0",
            border: "2px solid #fff",
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#050315",
      contrastText: "#fff",
    },
    secondary: {
      main: "#D8DAE5",
    },
    warning: {
      main: "#FCD463",
      contrastText: "#000",
    },
  },
  intouchText: {
    primary: "#1F2A51",
    gray: "#8891B0",
  },
});
