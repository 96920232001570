import { call, put, takeLatest } from "redux-saga/effects";
import API from "../api/API";
import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { setImageDatas, setUserDetails } from "../state/userSlice";
import { showSnackbarMessage } from "../state/snackbarSlice";
import { setLoading } from "../state/loaderSlice";
import {
  FETCH_INITIAL_DATA,
  UPDATE_IMAGE_DETAILS,
  UPDATE_USER_DETAILS,
  VERIFY_USER,
} from "./type";

function* fetchDataWorker(
  action: PayloadAction<{ userId: string; idIndex?: number; navigate?: any }>
): any {
  const { userId, navigate, idIndex } = action.payload;
  const existIndex: any = localStorage.getItem("ProfileIndex");

  let userIds = "";
  if (Array.isArray(userId) && userId.length === 1) {
    userIds = userId[0].id;
  } else if (Array.isArray(userId) && userId.length > 1) {
    userIds = idIndex
      ? userId[idIndex].id
      : existIndex
      ? userId[existIndex].id
      : userId[0].id;
    localStorage.setItem("userID", JSON.stringify(userId));
  } else {
    userIds = userId;
  }
  yield put(setLoading(true));
  try {
    const response: AxiosResponse = yield call(() =>
      API.get(`/smartCardDataItems/${userIds}`)
    );
    const ImageResponse: AxiosResponse = yield call(() =>
      API.get(`/smartCardImages/${userIds}`)
    );
    const userDetails = response.data ? response.data[0] : {};
    const imageList = ImageResponse.data ? ImageResponse.data : {};
    yield put(setImageDatas(imageList));
    yield put(setUserDetails({ user: userDetails }));
    yield put(setLoading(false));
    if (navigate) {
      navigate("/");
    }
  } catch (error) {
    console.error("error", error);
    yield put(setLoading(false));
  }
}

function* verifyUser(
  action: PayloadAction<{
    userEmail: string;
    instance: any;
    navigate?: any;
  }>
): any {
  const { userEmail, instance, navigate } = action.payload;
  yield put(setLoading(true));
  try {
    const response: AxiosResponse = yield call(() =>
      API.get(`/GetIdfromEmail/${userEmail}`)
    );
    localStorage.clear();
    yield put({
      type: FETCH_INITIAL_DATA,
      payload: { userId: response.data, navigate },
    });
    if (!response.data) {
      logoutRedirect({ postLogoutRedirectUri: "/login" });
    }
  } catch (error) {
    console.error("error", error);
    yield put(setLoading(false));
  }
}

function* updateUserdetailsWorker(action: PayloadAction<any>): any {
  const { id } = action.payload;
  yield put(setLoading(true));
  try {
    yield call(() =>
      API.put(`/smartCardDataUpdate/${id}`, { ...action.payload })
    );
    yield put({ type: FETCH_INITIAL_DATA, payload: { userId: id } });
    yield put(
      showSnackbarMessage({
        message: "Details has been updated",
        severity: "success",
      })
    );
    yield put(setLoading(false));
  } catch (error) {
    yield put(
      showSnackbarMessage({ message: "Something went wrong", severity: "info" })
    );
    console.error("error", error);
    yield put(setLoading(false));
  }
}

function* updateImageWorker(action: PayloadAction<any>): any {
  const { userId, ...rest } = action.payload;
  yield put(setLoading(true));
  try {
    yield call(() => API.post(`/smartCardImages`, { ...rest }));
    yield put({ type: FETCH_INITIAL_DATA, payload: { userId } });
    yield put(
      showSnackbarMessage({
        message: "Image has been uploaded successfully",
        severity: "success",
      })
    );
  } catch (error) {
    console.log(error, "error");
  }
}

function* updateTemplateWorker(action: PayloadAction<any>): any {
  const { userId, ...rest } = action.payload;
  yield put(setLoading(true));
  try {
    yield call(() => API.post(`/smartCardImages`, { ...rest }));
    yield put({ type: FETCH_INITIAL_DATA, payload: { userId } });
    yield put(
      showSnackbarMessage({
        message: "Image has been uploaded successfully",
        severity: "success",
      })
    );
  } catch (error) {
    console.log(error, "error");
  }
}

export default function* homepageSaga() {
  yield takeLatest(FETCH_INITIAL_DATA, fetchDataWorker);
  yield takeLatest(UPDATE_USER_DETAILS, updateUserdetailsWorker);
  yield takeLatest(UPDATE_IMAGE_DETAILS, updateImageWorker);
  yield takeLatest(VERIFY_USER, verifyUser);
}

function logoutRedirect(arg0: { postLogoutRedirectUri: string }) {
  throw new Error("Function not implemented.");
}
