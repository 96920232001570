import {
  Box,
  Grid,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import TextBox from "../../components/Home/TextBox";
import { Form, Formik, FormikProps } from "formik";
import { useEffect, useRef, useState } from "react";
import { IPersonalDetail } from "../../pages/Home/homepage.interface";
import { useAppDispatch, useAppSelector } from "../../app/store-hooks";
import * as Yup from "yup";
import ErrorText from "../Common/ErrorText/ErrorText";

interface IPersonalDetails {
  data: IPersonalDetail;
}
const initialValues = {
  firstName: "",
  middleName: "",
  lastName: "",
  prefix: "",
  suffix: "",
  preferredName: "",
  address: {
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    zip: "",
  },
};

const personalDetailsSchema = Yup.object().shape({
  address: Yup.object().shape({
    address1: Yup.string().required("Field is required"),
    city: Yup.string().required("Field is required"),
    state: Yup.string().required("Field is required"),
    country: Yup.string().required("Field is required"),
    zip: Yup.string().required("Field is required"),
  }),
});
const PersonalDetails = (props: IPersonalDetails) => {
  const dispatch = useAppDispatch();
  const userDetail = useAppSelector((state) => state.userReducer.userDetails);

  const formikRef = useRef<FormikProps<IPersonalDetail>>(null);
  const { data } = props;
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);
  const handleSubmit = (values: IPersonalDetail) => {
    const payload = {
      setNode: "/Personal",
      Personal: {
        ...values,
      },
      id: userDetail?.id,
    };
    dispatch({ type: "updateUserdetails", payload });
  };
  useEffect(() => {
    if (Object.keys(data).length) {
      if (formikRef.current) {
        const { setFieldValue } = formikRef.current;
        const existingValues = Object.entries(data);
        existingValues.forEach(([key, value]) => {
          if (value !== null) {
            setFieldValue(key, value);
          }
        });
      }
    }
  }, [data]);

  return (
    <Box sx={{ my: 2 }}>
      <Accordion
        sx={{ boxShadow: "none" }}
        expanded={isExpanded}
        onChange={() => setIsExpanded((prev) => !prev)}
      >
        <AccordionSummary expandIcon={<ExpandMore />} sx={{ px: 2 }}>
          <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
            Personal Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            innerRef={formikRef}
            validationSchema={personalDetailsSchema}
          >
            {({ handleChange, handleBlur, values, errors, touched }) => {
              const addressErrorObj = errors.address;
              return (
                <Form>
                  <Box
                    sx={{
                      borderTop: `1px solid ${theme.palette.secondary.main}`,
                      p: 2,
                    }}
                  >
                    <Grid container spacing={2} sx={{ my: 2 }}>
                      <Grid item xs={12} md={4}>
                        <TextBox
                          label="First Name"
                          name={"firstName"}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.firstName}
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextBox
                          label="Middle Name (optional)"
                          name={"middleName"}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.middleName}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextBox
                          label="Last Name"
                          name={"lastName"}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.lastName}
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextBox
                          label="Prefix"
                          name={"prefix"}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.prefix}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextBox
                          label="Suffix"
                          name={"suffix"}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.suffix}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextBox
                          label="Preferred Name"
                          name={"preferredName"}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.preferredName}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <TextBox
                          label="Address 1"
                          name={"address.address1"}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.address?.address1}
                          errorField={
                            touched.address?.address1 &&
                            addressErrorObj?.address1
                              ? true
                              : false
                          }
                          isRequired={true}
                        />
                        {touched.address?.address1 &&
                          addressErrorObj?.address1 && (
                            <ErrorText value={addressErrorObj?.address1} />
                          )}
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextBox
                          label="Address 2"
                          name={"address.address2"}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.address?.address2}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextBox
                          label="City"
                          name={"address.city"}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.address?.city}
                          errorField={
                            touched.address?.city && addressErrorObj?.city
                              ? true
                              : false
                          }
                          isRequired={true}
                        />
                        {touched.address?.city && addressErrorObj?.city && (
                          <ErrorText value={addressErrorObj?.city} />
                        )}
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextBox
                          label="State"
                          name={"address.state"}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.address?.state}
                          errorField={
                            touched.address?.state && addressErrorObj?.state
                              ? true
                              : false
                          }
                          isRequired={true}
                        />
                        {touched.address?.state && addressErrorObj?.state && (
                          <ErrorText value={addressErrorObj?.state} />
                        )}
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextBox
                          label="Country"
                          name={"address.country"}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.address?.country}
                          errorField={
                            touched.address?.country && addressErrorObj?.country
                              ? true
                              : false
                          }
                          isRequired={true}
                        />
                        {touched.address?.country &&
                          addressErrorObj?.country && (
                            <ErrorText value={addressErrorObj?.country} />
                          )}
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextBox
                          label="Zip"
                          name={"address.zip"}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.address?.zip}
                          errorField={
                            touched.address?.zip && addressErrorObj?.zip
                              ? true
                              : false
                          }
                          isRequired={true}
                        />
                        {touched.address?.zip && addressErrorObj?.zip && (
                          <ErrorText value={addressErrorObj?.zip} />
                        )}
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        width: "100%",
                        my: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        size="small"
                        type="submit"
                        sx={{
                          color: theme.palette.warning.contrastText,
                          bgcolor: theme.palette.warning.main,
                        }}
                      >
                        Save
                      </Button>
                    </Box>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default PersonalDetails;
