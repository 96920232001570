import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "../components/Layout";
import ErrorPage from "../pages/ErrorPage";
import SnackbarWrapper from "../components/snackbar";
import HomePage from "../pages/Home/HomePage";
import Login from "../pages/Login/Login";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

export default function AppRoutes() {
  return (
    <>
      <SnackbarWrapper />
      <BrowserRouter basename="/">
        <Layout>
          <Routes>
            <Route
              path="/"
              element={
                //Todo: Need to wrap with privateroute after development
                <HomePage />
              }
            />
            <Route
              path="/:id"
              element={
                //Todo: Remove once the login flow is completed
                <HomePage />
              }
            />
            <Route
              path="/login"
              element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              }
            />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </>
  );
}
