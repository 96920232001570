import {
  Alert,
  Grid,
  Slide,
  SlideProps,
  Snackbar,
  Typography,
} from "@mui/material";
import React from "react";
import { useAppDispatch, useAppSelector } from "../app/store-hooks";
import { RootState } from "../app/store";
import { clearSnackbarMessage } from "../state/snackbarSlice";

function TransitionUp(props: JSX.IntrinsicAttributes & SlideProps) {
  return <Slide {...props} direction="up" />;
}

export default function SnackbarWrapper() {
  const dispatch = useAppDispatch();
  const { open, message, action, severity } = useAppSelector(
    (state: RootState) => state.snackbar
  );

  const handleClose = () => {
    dispatch(clearSnackbarMessage());
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      message={message}
      TransitionComponent={TransitionUp}
    >
      {severity ? (
        <Alert severity={severity}>{message}</Alert>
      ) : (
        <Grid
          container
          alignItems="center"
          sx={{
            border: "black",
            padding: "16px",
            borderRadius: "5px",
            width: "360px",
            backgroundColor: "#333333",
            color: "#fff",
          }}
        >
          <Grid xs={8}>
            <Typography sx={{ fontSize: "14px" }}>{message}</Typography>
          </Grid>
          <Grid xs={4} container sx={{ justifyContent: "end" }}>
            {action}
          </Grid>
        </Grid>
      )}
    </Snackbar>
  );
}
