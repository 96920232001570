import { Avatar, Box, Button, Typography } from "@mui/material";

interface ILink {
  label: string;
  value: string;
  name: string;
}
interface IAppTileProps {
  src?: string;
  link: ILink;
  handleClick: (link: ILink) => void;
}
const AppTile = (props: IAppTileProps) => {
  const { src, link, handleClick } = props;
  return (
    <Box
      component={Button}
      onClick={() => handleClick(link)}
      sx={{
        backgroundColor: "#FAFAFA",
        height: "5rem",
        width: "80%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 1,
        p: 1,
        textTransform: "capitalize",
      }}
    >
      <Box
        sx={{
          width: "3rem",
          height: "3rem",
          padding: "5px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Avatar
          sx={{ fontSize: "small", borderRadius: "5px" }}
          alt=""
          src={src}
        />
      </Box>
      <Typography
        variant="body1"
        sx={{ textAlign: "center", fontSize: "12px", color: "#858585" }}
      >
        {link?.label}
      </Typography>
    </Box>
  );
};

export default AppTile;
