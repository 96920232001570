import { Box, Button, Modal, Paper, Typography, useTheme } from "@mui/material";
import CoverImage from "../../../assets/images/upgradPlanCover.jpg";

interface IProps {
  showModal: boolean;
  handleCloseModal: () => void;
  userName: string;
}

function UpgradePlanModal({ showModal, handleCloseModal, userName }: IProps) {
  const theme = useTheme();
  return (
    <Modal
      open={showModal}
      onClose={handleCloseModal}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          borderRadius: "0.5rem",
          width: "25rem",
          outline: "none",
        }}
        component={Paper}
        elevation={0}
        className="templateModal"
      >
        <Box sx={{ width: "100%", height: "15rem" }}>
          <img
            src={CoverImage}
            alt="coverImage"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderTopLeftRadius: ".5rem",
              borderTopRightRadius: ".5rem",
            }}
          />
        </Box>
        <Box sx={{ padding: "0 30px 30px 30px" }}>
          <Typography
            sx={{
              fontSize: "28px",
              fontWeight: "700",
              color: theme.intouchText?.primary,
              marginBottom: "15px",
            }}
          >
            Dear {userName}
          </Typography>
          <Typography
            sx={{
              color: theme.intouchText?.gray,
              borderBottom: `1px solid ${theme.intouchText?.gray}`,
              paddingBottom: "15px",
            }}
          >
            For more feature and a better user experience, please upgrade the
            current plan
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "15px",
            }}
          >
            <Button
              sx={{
                backgroundColor: "lightgreen",
                padding: "10px 25px",
                color: "white",
                ":hover": {
                  backgroundColor: "lightgreen",
                },
              }}
            >
              UPGRADE
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default UpgradePlanModal;
