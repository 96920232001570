export const renderUserName = (firstName: string, lastName: string) =>
  firstName || lastName ? `${firstName} ${lastName}` : false;

export const textCapitalize = (string: string) => {
  let capitalized = "";
  const splitWord = string.split(" ");

  splitWord.forEach((word) => {
    const initial = word.slice(0, 1);
    const rest = word.slice(1);
    capitalized += `${
      capitalized !== "" ? " " : ""
    }${initial.toUpperCase()}${rest}`;
  });
  return capitalized;
};

export const setDefaultMediaName = (appName: string) => {
  if (appName === "Emails") {
    return "Email";
  } else if (appName === "Phones") {
    return "Phone";
  } else if (appName === "Urls") {
    return "Url";
  } else if (appName === "Links") {
    return "Link";
  } else {
    return appName;
  }
};

export const getLabelNameTextBox = (appName: string) => {
  if (appName === "Phones") {
    return "Label";
  } else {
    return "Link title";
  }
};

export const getLabelValueTextBox = (appName: string) => {
  if (appName === "PayPal") {
    return "paypal.me link";
  } else if (appName === "YouTube") {
    return "Youtube user/channel link";
  } else if (appName === "LinkedIn") {
    return "Linkedin profile link";
  } else {
    return "Value";
  }
};

export const getRespectiveLink = (appName: string, linkValue: string) => {
  if (linkValue !== "") {
    if (appName === "WhatsApp") {
      return `https://wa.me/${linkValue}`;
    } else {
      return `${linkValue}`;
    }
  } else {
    return "";
  }
};
