import { useIsAuthenticated, UnauthenticatedTemplate } from "@azure/msal-react";
import React, { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";

const PublicRoute = (props: PropsWithChildren) => {
  const { children } = props;
  const isAuthenticated = useIsAuthenticated();

  return isAuthenticated ? (
    <Navigate to={"/"} />
  ) : (
    <UnauthenticatedTemplate>{children}</UnauthenticatedTemplate>
  );
};

export default PublicRoute;
