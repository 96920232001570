import { CloseRounded } from "@mui/icons-material";
import { Box, Button, Modal, Paper, Typography, useTheme } from "@mui/material";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import "./buyTemplateModal.scss";
import Template01 from "../../../assets/svg/template-basic01.svg";
import Template02 from "../../../assets/svg/template-basic02.svg";
import Template03 from "../../../assets/svg/template-basic03.svg";
import Template04 from "../../../assets/svg/template-basic04.svg";
import RightArrow from "../../../assets/svg/rightArrow.svg";
import LeftArrow from "../../../assets/svg/leftArrow.svg";
import { useState } from "react";

interface IProps {
  showModal: boolean;
  handleCloseModal: () => void;
  selectedTemplate?: number | any;
  availableTemplates?: string[];
  handleTemplateUpdate: (e: string) => void;
}

function BuyTemplateModal({
  showModal,
  handleCloseModal,
  selectedTemplate,
  availableTemplates,
  handleTemplateUpdate,
}: IProps) {
  const [activePage, setActivePage] = useState(0);
  const templatePerPage = 3;
  let startIndex = activePage * templatePerPage;
  let endIndex = activePage * templatePerPage + templatePerPage;
  const theme = useTheme();

  const templateObject: any = {
    Basic01: Template01,
    Basic02: Template02,
    Basic03: Template03,
    Basic04: Template04,
  };

  return (
    <Modal
      open={showModal}
      onClose={handleCloseModal}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          borderRadius: "0.25rem",
          p: 4,
          [theme.breakpoints.up("md")]: { width: "35vw" },
          minWidth: "80vw",
        }}
        component={Paper}
        elevation={0}
        className="templateModal"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
            padding: "0 10px",
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "1rem",
              textTransform: "capitalize",
            }}
          >
            Page Template
          </Typography>
          <CloseRounded
            sx={{ color: "#8F95B2", cursor: "pointer" }}
            onClick={handleCloseModal}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "40px",
            overflowX: "auto",
            overflowY: "hidden",
            padding: "20px 10px",
            justifyContent: "space-between",
          }}
          className="templateModal-content"
        >
          <Box>
            {endIndex !== templatePerPage && (
              <img
                src={LeftArrow}
                alt="leftArrow"
                style={{ cursor: "pointer" }}
                onClick={() => setActivePage((prev) => prev - 1)}
              />
            )}
          </Box>
          {Object.keys(templateObject)
            .slice(startIndex, endIndex)
            .map((key, i) => {
              const templateSrc = templateObject[key];
              const keyTemp = Object.keys(templateObject);
              return (
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className="templateModal-content-container"
                >
                  <Box
                    sx={{ height: "30rem" }}
                    className={`templateModal-content-container-cover-${
                      key !== selectedTemplate ? "show" : ""
                    }`}
                  >
                    <img
                      alt={`template-${key}`}
                      src={templateSrc}
                      className="templateModal-content-container-cover-image"
                    />
                  </Box>
                  {key === selectedTemplate && (
                    <Box
                      sx={{
                        backgroundColor: "white",
                        width: "4.5rem",
                        height: "4.5rem",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "absolute",
                        bottom: "-1rem",
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: theme.palette.warning.main,
                          width: "4rem",
                          height: "4rem",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <TaskAltOutlinedIcon
                          fontSize="large"
                          htmlColor="white"
                        />
                      </Box>
                    </Box>
                  )}
                  {key !== selectedTemplate && (
                    <Box className="templateModal-content-container-buyActionContainer">
                      {availableTemplates &&
                      Array.isArray(availableTemplates) &&
                      availableTemplates.length > 0 &&
                      availableTemplates.filter((tempName) => tempName === key)
                        .length > 0 ? (
                        <Button
                          sx={{
                            color: theme.palette.warning.contrastText,
                            bgcolor: theme.palette.warning.main,
                            borderRadius: "5px",
                            width: "8rem",
                            ":hover": {
                              bgcolor: theme.palette.warning.main,
                            },
                          }}
                          onClick={() => handleTemplateUpdate(keyTemp[i])}
                        >
                          Set Default
                        </Button>
                      ) : (
                        <>
                          <Button
                            sx={{
                              color: theme.palette.warning.contrastText,
                              bgcolor: theme.palette.warning.main,
                              borderRadius: "5px",
                              width: "8rem",
                              ":hover": {
                                bgcolor: theme.palette.warning.main,
                              },
                            }}
                          >
                            Buy Template
                          </Button>
                          <Button
                            sx={{
                              color: theme.palette.warning.contrastText,
                              bgcolor: "white",
                              borderRadius: "5px",
                              width: "8rem",
                              ":hover": {
                                bgcolor: "white",
                              },
                            }}
                            onClick={() => handleTemplateUpdate(keyTemp[i])}
                          >
                            Set Preview
                          </Button>
                        </>
                      )}
                    </Box>
                  )}
                </Box>
              );
            })}
          <Box>
            {Object.keys(templateObject).length > endIndex && (
              <img
                src={RightArrow}
                alt="rightArrow"
                style={{ cursor: "pointer" }}
                onClick={() => setActivePage((prev) => prev + 1)}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default BuyTemplateModal;
