import { CssBaseline } from "@mui/material";

type LayoutProps = {
    children: React.ReactNode
}



export default function Layout(layoutProps: LayoutProps) {
    const { children } = layoutProps;
    return (
        <>
            <CssBaseline />
            <div className="layout-container">
                {children}
            </div>
        </>
    )
}