import { Avatar, Box, Grid, Switch, Typography } from "@mui/material";
import "./basicInfo.scss";
import { IImageList } from "../../pages/Home/homepage.interface";

interface IProps {
  setShowProfilleEditor: (e: any) => void;
  imageList: IImageList;
  subscriptionType: string | undefined;
  setShowUpgradePlanModal: (e: any) => void;
}

const BasicInfo = ({
  setShowProfilleEditor,
  imageList,
  subscriptionType,
  setShowUpgradePlanModal,
}: IProps) => {
  const profileImage = imageList?.profilePicture;
  const companyLogo = imageList?.companyLogo;
  const CoverImage = imageList?.coverPhoto;

  const openProfileModal = (title: string) => {
    switch (subscriptionType) {
      case "Standard":
        if (title === "profile image") {
          setShowProfilleEditor({ show: true, header: title });
        } else {
          setShowUpgradePlanModal(true);
        }
        break;
      case "Gold":
        if (title === "profile image" || title === "company image") {
          setShowProfilleEditor({ show: true, header: title });
        } else {
          setShowUpgradePlanModal(true);
        }
        break;
      case "Platinum":
        setShowProfilleEditor({ show: true, header: title });
        break;
      default:
        break;
    }
  };

  return (
    <Box>
      <Typography
        sx={{ fontWeight: 600, fontSize: "14px", marginBottom: "20px" }}
      >
        Basic info
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={4} lg={2}>
          <div className="info">
            <div className="info-container">
              {profileImage && profileImage.imageStream ? (
                <div
                  className="info-container-profile"
                  onClick={() => openProfileModal("profile image")}
                >
                  <img
                    className="info-container-profile-image"
                    alt="profile"
                    src={`data:${profileImage.imageType};base64,${profileImage.imageStream}`}
                  />
                </div>
              ) : (
                <div
                  className="info-container-defaultProfile"
                  onClick={() => openProfileModal("profile image")}
                >
                  <Avatar alt="" src="/static/images/avatar/1.jpg" />
                </div>
              )}
            </div>
            <Typography
              variant="body1"
              sx={{
                textAlign: "center",
                fontSize: "12px",
                color: "#858585",
              }}
            >
              Profile Photo
            </Typography>
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Switch size="small" />
            </Box>
          </div>
        </Grid>
        <Grid item xs={6} sm={4} lg={2}>
          <div className="info">
            <div className="info-container">
              {companyLogo && companyLogo.imageStream ? (
                <div
                  className="info-container-company"
                  onClick={() => openProfileModal("company image")}
                >
                  <img
                    className="info-container-company-image"
                    alt="companyLogo"
                    src={`data:${companyLogo.imageType};base64,${companyLogo.imageStream}`}
                  />
                </div>
              ) : (
                <div
                  className="info-container-defaultProfile"
                  onClick={() => openProfileModal("company image")}
                >
                  <Avatar alt="" src="/static/images/avatar/1.jpg" />
                </div>
              )}
            </div>
            <Typography
              variant="body1"
              sx={{
                textAlign: "center",
                fontSize: "12px",
                color: "#858585",
              }}
            >
              Brand
            </Typography>
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Switch size="small" />
            </Box>
          </div>
        </Grid>
        <Grid item xs={6} sm={4} lg={2}>
          <div className="info">
            <div className="info-container">
              {CoverImage && CoverImage.imageStream ? (
                <div
                  className="info-container-cover"
                  onClick={() => openProfileModal("cover image")}
                >
                  <img
                    className="info-container-cover-image"
                    alt="coverImage"
                    src={`data:${CoverImage.imageType};base64,${CoverImage.imageStream}`}
                  />
                </div>
              ) : (
                <div
                  className="info-container-defaultProfile"
                  onClick={() => openProfileModal("cover image")}
                >
                  <Avatar alt="" src="/static/images/avatar/1.jpg" />
                </div>
              )}
            </div>
            <Typography
              variant="body1"
              sx={{
                textAlign: "center",
                fontSize: "12px",
                color: "#858585",
              }}
            >
              Cover
            </Typography>
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Switch size="small" />
            </Box>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BasicInfo;
