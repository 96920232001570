import { Grid } from "@mui/material";
import { IUserDetails } from "../../../pages/Home/homepage.interface";
import { useEffect, useState } from "react";
import EmailLogo from "../../../assets/svg/outlook-icon.svg";
import PhoneLogo from "../../../assets/svg/phonecall-icon.svg";
import WhatsappLogo from "../../../assets/svg/whatsapp-icon.svg";
import InstagramLogo from "../../../assets/svg/instagram-icon.svg";
import YoutubeLogo from "../../../assets/svg/youtube-icon.svg";
import SnapChatLogo from "../../../assets/svg/snapchat-icon.svg";
import URLLogo from "../../../assets/svg/phone-icon.svg";
import VenmoLogo from "../../../assets/svg/vimeo-icon.svg";
import PaypalLogo from "../../../assets/svg/paypal-icon.svg";
import DiscordLogo from "../../../assets/svg/discord-icon.svg";
import SignalLogo from "../../../assets/svg/signal-icon.svg";
import SkypeLogo from "../../../assets/svg/skype-icon.svg";
import TelegramLogo from "../../../assets/svg/telegram-icon.svg";
import CashappLogo from "../../../assets/svg/cashapp-icon.svg";
import LinkedinLogo from "../../../assets/svg/linkedin-icon.svg";
import TwitterLogo from "../../../assets/svg/twitter-icon.svg";
import FacebookLogo from "../../../assets/svg/facebook-icon.svg";
import TikTokLogo from "../../../assets/svg/tiktok-icon.svg";
import TwitchLogo from "../../../assets/svg/twitch-icon.svg";
import YelpLogo from "../../../assets/svg/yelp-icon.svg";
import "./socialMediaList.scss";

interface IProps {
  details: IUserDetails;
}

function SocialMediaList({ details }: IProps) {
  const [availableSocialMedias, setAvailableSocialMedias] = useState([]);

  const renderImage = (media: string) => {
    switch (media) {
      case "emails":
        return EmailLogo;
      case "phones":
        return PhoneLogo;
      case "whatsApp":
        return WhatsappLogo;
      case "instagram":
        return InstagramLogo;
      case "youTube":
        return YoutubeLogo;
      case "snapChat":
        return SnapChatLogo;
      case "urls" || "links":
        return URLLogo;
      case "venmo":
        return VenmoLogo;
      case "payPal":
        return PaypalLogo;
      case "discord":
        return DiscordLogo;
      case "signal":
        return SignalLogo;
      case "skype":
        return SkypeLogo;
      case "telegram":
        return TelegramLogo;
      case "cashApp":
        return CashappLogo;
      case "linkedIn":
        return LinkedinLogo;
      case "twitter":
        return TwitterLogo;
      case "facebook":
        return FacebookLogo;
      case "tikTok":
        return TikTokLogo;
      case "twitch":
        return TwitchLogo;
      case "yelp":
        return YelpLogo;
      default:
        return "";
    }
  };

  useEffect(() => {
    const medias: any = [];
    if (details && Object.keys(details).length > 0) {
      if (
        details?.messaging &&
        typeof details?.messaging === "object" &&
        Object.keys(details?.general).length > 0
      ) {
        const messagingMedias: any = details.messaging;
        Object.keys(messagingMedias).forEach((key) => {
          if (
            messagingMedias[key] &&
            Object.values(messagingMedias[key]).filter(
              (val) => val === null || val === ""
            ).length === 0
          ) {
            let detail = { ...messagingMedias[key] };
            detail.type = key;
            medias.push(detail);
          }
        });
      }
      if (
        details?.social &&
        typeof details?.social === "object" &&
        Object.keys(details?.social).length > 0
      ) {
        const socialMedia: any = details.social;
        Object.keys(socialMedia).forEach((key) => {
          if (
            socialMedia[key] &&
            Object.values(socialMedia[key]).filter(
              (val) => val === null || val === ""
            ).length === 0
          ) {
            let detail = { ...socialMedia[key] };
            detail.type = key;
            medias.push(detail);
          }
        });
      }
      if (details?.general && Object.keys(details?.general).length > 0) {
        const generalDetails: any = details.general;
        Object.keys(generalDetails).forEach((key) => {
          if (generalDetails[key] && Array.isArray(generalDetails[key])) {
            const socialMediaArr = generalDetails[key];
            socialMediaArr.forEach((item: any) => {
              if (
                Object.values(item).filter((val) => val === null || val === "")
                  .length === 0
              ) {
                let detail = { ...item };
                detail.type = key;
                medias.push(detail);
              }
            });
          } else if (
            generalDetails[key] &&
            typeof generalDetails[key] === "object" &&
            Object.keys(generalDetails[key]).length > 0 &&
            Object.values(generalDetails[key]).filter(
              (val) => val === null || val === ""
            ).length === 0
          ) {
            let detail = { ...generalDetails[key] };
            detail.type = key;
            medias.push(detail);
          }
        });
      }
      if (
        details?.payment &&
        typeof details?.payment === "object" &&
        Object.keys(details?.payment).length > 0
      ) {
        const paymentMedia: any = details.payment;
        Object.keys(paymentMedia).forEach((key) => {
          if (
            paymentMedia[key] &&
            Object.values(paymentMedia[key]).filter(
              (val) => val === null || val === ""
            ).length === 0
          ) {
            let detail = { ...paymentMedia[key] };
            detail.type = key;
            medias.push(detail);
          }
        });
      }
    }
    setAvailableSocialMedias(medias);
  }, [details]);

  return (
    <Grid
      container
      style={{ padding: "" }}
      rowSpacing={1}
      className="mediaGrid"
    >
      {availableSocialMedias.map((item: any, idx: number) => (
        <div key={`socialMEdia-${idx}`} className="mediaGrid-imageWrapper">
          <img
            src={renderImage(item.type)}
            alt={`${item.type}`}
            className="mediaGrid-imageWrapper-image"
          />
          <span className="mediaGrid-imageWrapper-name">{item.name}</span>
        </div>
      ))}
    </Grid>
  );
}

export default SocialMediaList;
