import React from "react";
import { Box, Typography } from "@mui/material";
interface ITextBoxProps {
  label: string;
  placeholder?: string;
  gutterBottem?: boolean;
  name?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  value?: string;
  disabled?: boolean;
  errorField?: boolean;
  isRequired?: boolean;
}
const TextBox = (props: ITextBoxProps) => {
  const {
    label,
    placeholder,
    gutterBottem,
    name,
    onChange,
    onBlur,
    value,
    disabled,
    errorField,
    isRequired = false,
  } = props;
  return (
    <Box
      sx={{
        width: "100%",
        "& input": {
          width: "100%",
          border: "1px solid #D8DAE5",
          borderRadius: "4px",
          p: 1,
          outline: "none",
        },
      }}
    >
      <Typography
        sx={{ fontWeight: 500, fontSize: "14px", color: "#474D66" }}
        gutterBottom={gutterBottem}
      >
        {label}
        {isRequired && (
          <span className={`${errorField ? "errorText" : "starNotation"}`}>
            *
          </span>
        )}
      </Typography>
      <input
        placeholder={placeholder || ""}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        className={`${disabled ? "fieldDisabled" : ""} ${
          errorField ? "errorField" : ""
        }`}
      />
    </Box>
  );
};

export default TextBox;
