import { AddRounded, CloseRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import TextBox from "../Home/TextBox";
import { IAppType } from "../../pages/Home/homepage.interface";
import { mailRegex, phoneRegex } from "../../utils/Constants";
import ErrorText from "./ErrorText/ErrorText";
import {
  getLabelNameTextBox,
  getLabelValueTextBox,
  getRespectiveLink,
} from "../../utils/helperFunctions";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";

interface IProps {
  open: boolean;
  selectedAppName: string;
  selectedApp: IAppType | any;
  handleRemoveField: (e: number) => void;
  handleAddNewFeild: () => void;
  handleUpdate: () => void;
  handleClose: () => void;
  setSelectedApp: (e: any) => void;
  addMoreAllowedFields: any[];
}

function UpdateDetailsCard({
  handleClose,
  open,
  selectedAppName,
  selectedApp,
  handleRemoveField,
  handleAddNewFeild,
  handleUpdate,
  setSelectedApp,
  addMoreAllowedFields,
}: IProps) {
  const theme = useTheme();
  const type = selectedAppName;
  const [errorObject, setErrorObject] = useState<any>(null);

  const modalClose = () => {
    setErrorObject(null);
    handleClose();
  };

  const removeField = (index: number) => {
    let copiedErrorObj = [...errorObject];
    copiedErrorObj.splice(index, 1);
    setErrorObject(copiedErrorObj);
    handleRemoveField(index);
  };

  const addField = () => {
    let copiedErrorObj = [...errorObject];
    copiedErrorObj.push({ name: "", value: "" });
    setErrorObject(copiedErrorObj);
    handleAddNewFeild();
  };

  const mailValidation = (value: string) => {
    if (String(value).toLowerCase().match(mailRegex)) {
      return true;
    }
  };

  const phoneValidation = (value: string) => {
    if (String(value).match(phoneRegex)) {
      return true;
    }
  };

  const regexValidation = (name: string, type: string, value: string) => {
    if (name === "value" && type === "Emails") {
      return mailValidation(value);
    } else if (name === "value" && (type === "Phones" || type === "WhatsApp")) {
      return phoneValidation(value);
    } else {
      return false;
    }
  };

  const handleFieldValidate = (name: string, value: string, index: number) => {
    if (errorObject && Array.isArray(errorObject)) {
      let errorObj = [...errorObject];
      if (name === "name") {
        if (value === "") {
          errorObj[index][name] = "Field is required";
        } else {
          errorObj[index][name] = "";
        }
      }
      if (name === "value") {
        if (value === "") {
          errorObj[index][name] = "Field is required";
        } else if (!regexValidation(name, type, value)) {
          errorObj[index][name] = `${
            type === "Emails"
              ? "Enter a valid email"
              : type === "Phones"
              ? "Enter a valid phone number"
              : ""
          }`;
        } else {
          errorObj[index][name] = "";
        }
      }
      setErrorObject(errorObj);
    } else {
      let errorObj = { ...errorObject };
      if (value === "") {
        errorObj[name] = "Field is required";
      } else if (!regexValidation(name, type, value)) {
        errorObj[name] =
          type === "WhatsApp" ? "Enter a valid phone number" : "";
      } else {
        errorObj[name] = "";
      }
      setErrorObject(errorObj);
    }
  };

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index?: any
  ) => {
    const { name, value } = e.target;
    if (Array.isArray(selectedApp)) {
      const copyArrayVal = [...selectedApp];
      let currentObject = { ...copyArrayVal[index] };
      currentObject[name] = value;
      copyArrayVal[index] = currentObject;
      setSelectedApp(copyArrayVal);
    } else {
      setSelectedApp((prev: any) => ({ ...prev, [name]: value }));
    }
    handleFieldValidate(name, value, index);
  };

  const emptyFieldCheck = () => {
    let emptyFields = 0;
    if (selectedApp && Array.isArray(selectedApp)) {
      selectedApp.forEach((field) => {
        Object.values(field).forEach((val) => {
          if (val === "") {
            emptyFields += 1;
          }
        });
      });
    } else {
      Object.values(selectedApp).forEach((val) => {
        if (val === "") {
          emptyFields += 1;
        }
      });
    }
    if (emptyFields === 0) {
      return true;
    } else {
      return false;
    }
  };

  const errorFieldCheck = () => {
    let errorFields = 0;
    if (errorObject && Array.isArray(errorObject)) {
      errorObject.forEach((field) => {
        Object.values(field).forEach((val) => {
          if (val !== "") {
            errorFields += 1;
          }
        });
      });
    } else {
      Object.values(errorObject).forEach((val) => {
        if (val !== "") {
          errorFields += 1;
        }
      });
    }
    if (errorFields === 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleSave = () => {
    if (emptyFieldCheck() && errorFieldCheck()) {
      handleUpdate();
      modalClose();
    }
  };

  useEffect(() => {
    if (!errorObject && selectedApp) {
      if (selectedApp && Array.isArray(selectedApp)) {
        let errorObj: any = [];
        selectedApp.forEach((_) => {
          errorObj.push({ name: "", value: "" });
        });
        setErrorObject(errorObj);
      } else {
        setErrorObject({ name: "", value: "" });
      }
    }
  }, [selectedApp]);

  return (
    <Modal
      open={open}
      onClose={modalClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          borderRadius: "0.25rem",
          p: 2,
          [theme.breakpoints.up("md")]: { width: "35vw" },
          width: "70vw",
        }}
        component={Paper}
        elevation={0}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "1rem",
              textTransform: "capitalize",
            }}
          >
            {selectedAppName}
          </Typography>
          <CloseRounded
            sx={{ color: "#8F95B2", cursor: "pointer" }}
            onClick={modalClose}
          />
        </Box>
        <Box sx={{ my: 2, maxHeight: "80vh", overflow: "auto" }}>
          <Grid container spacing={2}>
            {Array.isArray(selectedApp) &&
            addMoreAllowedFields.includes(selectedAppName) ? (
              <>
                {selectedApp.map((details: any, index: number) => (
                  <Fragment key={index}>
                    <Grid item xs={12}>
                      <TextBox
                        label={getLabelNameTextBox(selectedAppName)}
                        placeholder="Enter label here..."
                        gutterBottem
                        name="name"
                        value={details.name}
                        onBlur={() => {}}
                        onChange={(event) => handleFieldChange(event, index)}
                        disabled={
                          index === 0 &&
                          (type === "Emails" || type === "Phones") &&
                          details.name !== ""
                        }
                        errorField={
                          errorObject &&
                          errorObject[index]?.name &&
                          errorObject[index]?.name !== ""
                            ? true
                            : false
                        }
                      />
                      {errorObject && errorObject[index]?.name && (
                        <ErrorText value={errorObject[index]?.name} />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <TextBox
                        label={getLabelValueTextBox(selectedAppName)}
                        placeholder="Enter your email here..."
                        gutterBottem
                        name="value"
                        value={details.value}
                        onBlur={() => {}}
                        onChange={(event) => handleFieldChange(event, index)}
                        disabled={
                          index === 0 &&
                          (type === "Emails" || type === "Phones") &&
                          details.value !== ""
                        }
                        errorField={
                          errorObject &&
                          errorObject[index]?.value &&
                          errorObject[index]?.value !== ""
                            ? true
                            : false
                        }
                      />
                      {errorObject && errorObject[index]?.value && (
                        <ErrorText value={errorObject[index]?.value} />
                      )}
                    </Grid>
                    {selectedAppName !== "Emails" &&
                      selectedAppName !== "Phones" &&
                      selectedAppName !== "About" && (
                        <Grid xs={12}>
                          <a
                            href={getRespectiveLink(
                              selectedAppName,
                              details.value
                            )}
                            target="_blank"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              padding: "16px",
                              fontSize: "14px",
                              textDecoration: "none",
                            }}
                          >
                            Test your link
                            <OpenInNewOutlinedIcon sx={{ fontSize: "16px" }} />
                          </a>
                        </Grid>
                      )}
                  </Fragment>
                ))}
                <Button
                  startIcon={<AddRounded />}
                  color="warning"
                  sx={{ m: 1, fontSize: "0.75rem" }}
                  onClick={addField}
                >
                  Add More
                </Button>
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <TextBox
                    label={getLabelNameTextBox(selectedAppName)}
                    name="name"
                    placeholder="Enter label here..."
                    gutterBottem
                    value={selectedApp?.name}
                    onBlur={() => {}}
                    onChange={handleFieldChange}
                    errorField={
                      errorObject &&
                      errorObject?.name &&
                      errorObject?.name !== ""
                        ? true
                        : false
                    }
                  />
                  {errorObject && errorObject?.name && (
                    <ErrorText value={errorObject?.name} />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextBox
                    label={getLabelValueTextBox(selectedAppName)}
                    name="value"
                    placeholder="Enter Value here..."
                    gutterBottem
                    value={selectedApp?.value}
                    onBlur={() => {}}
                    onChange={handleFieldChange}
                    errorField={
                      errorObject &&
                      errorObject?.value &&
                      errorObject?.value !== ""
                        ? true
                        : false
                    }
                  />
                  {errorObject && errorObject?.value && (
                    <ErrorText value={errorObject?.value} />
                  )}
                </Grid>
                <Box>
                  <a
                    href={getRespectiveLink(
                      selectedAppName,
                      selectedApp?.value
                    )}
                    target="_blank"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      padding: "16px",
                      fontSize: "14px",
                      textDecoration: "none",
                    }}
                  >
                    Test your link
                    <OpenInNewOutlinedIcon sx={{ fontSize: "16px" }} />
                  </a>
                </Box>
              </>
            )}
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 1,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography
                sx={{ fontWeight: 500, fontSize: "14px", color: "#474D66" }}
              >
                Visibility
              </Typography>
              <Switch size="small" sx={{ color: theme.palette.warning.main }} />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Button
                size="small"
                sx={{ color: "#696F8C" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                size="small"
                sx={{
                  color: theme.palette.warning.contrastText,
                  bgcolor: theme.palette.warning.main,
                }}
                onClick={handleSave}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default UpdateDetailsCard;
