import {
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  Modal,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import Logo from "../../assets/images/Logo.png";
import FilterIcon from "../../assets/icons/filter.svg";
import "./login.scss";
import { SetStateAction, useEffect, useState } from "react";
import intouchLogo from "../../assets/svg/intouch-logo.svg";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { FETCH_INITIAL_DATA, VERIFY_USER } from "../../sagas/type";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/store-hooks";
import { loginRequest } from "../../authConfig";
import { callMsGraph } from "../../graph";

const Login = () => {
  // const [open, setOpen] = useState(false);
  const isLoading: boolean = useAppSelector(
    (state) => state.appLoadingReducer.isLoading
  );

  const { instance } = useMsal();

  const LOGIN_REDIRECT_URL =
    "https://SaaSGenieorg.b2clogin.com/SaaSGenieorg.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_intouchadmin&client_id=19e4cffc-ed82-4c12-819b-184811ac374a&nonce=defaultNonce&redirect_uri=http%3A%2F%2Flocalhost%3A3000&scope=openid&response_type=code&prompt=login&code_challenge_method=S256&code_challenge=BL-SgpGuUV9stpbJGJ6gmUAL5iYpB7FQXFZars6vG1A";

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    border: " 1px solid #F2F2F2",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    height: "35rem",
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
  };

  const theme = useTheme();

  const handleLogin = () => {
    instance.loginPopup(loginRequest).catch((e) => {
      console.log(e);
    });
  };

  return (
    <>
      <Box
        sx={{
          px: 4,
          py: 2,
        }}
      >
        <Box>
          <img src={Logo} alt="Logo" className="logo" />
          <Typography variant="h6" sx={{ fontWeight: 700 }} gutterBottom>
            Sign in for faster checkout.
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "100%",
              [theme.breakpoints.up("lg")]: { width: "55%" },
              [theme.breakpoints.up("xl")]: { width: "50%" },
              [theme.breakpoints.up("md")]: { width: "70%" },
            }}
          >
            <Typography
              component={"h6"}
              sx={{ textAlign: "center", fontWeight: 600 }}
              gutterBottom
            >
              Sign in to inTouch Application
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ fontSize: "small", textAlign: "center" }}
            >
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Soluta
              consequuntur nulla expedita architecto eum, adipisci inventore
              natus neque necessitatibus officiis animi distinctio totam optio
              doloremque, maxime a beatae at culpa vel corrupti exercitationem
              autem fuga iusto quia!.
            </Typography>
          </Box>
          <Box sx={{ p: 4 }}>
            <Button
              // href={LOGIN_REDIRECT_URL}
              onClick={handleLogin}
              variant="contained"
              color="primary"
              sx={{ borderRadius: "49px", textTransform: "none" }}
              disableElevation
            >
              Sign In
            </Button>
          </Box>
          <Grid
            container
            sx={{
              width: "100%",
              [theme.breakpoints.up("lg")]: { width: "55%" },
              [theme.breakpoints.up("xl")]: { width: "50%" },
              [theme.breakpoints.up("md")]: { width: "70%" },
            }}
            spacing={2}
          >
            <Grid item xs={12} md={6}>
              <Paper elevation={0} sx={{ p: 2, height: "100%" }}>
                <Box>
                  <img src={FilterIcon} alt="" className="filterIcon" />
                </Box>
                <Typography
                  variant={"h5"}
                  sx={{ fontWeight: 700 }}
                  gutterBottom
                >
                  You're in Control
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ fontSize: "small" }}
                >
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Soluta consequuntur nulla expedita architecto eum, adipisci
                  inventore natus neque necessitatibus officiis animi distinctio
                  totam optio doloremque, maxime a beatae at culpa vel corrupti
                  exercitationem autem fuga iusto quia!.
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper elevation={0} sx={{ p: 2, height: "100%" }}>
                <Box>
                  <img src={FilterIcon} alt="" className="filterIcon" />
                </Box>
                <Typography
                  variant={"h5"}
                  sx={{ fontWeight: 700 }}
                  gutterBottom
                >
                  Private and Secure
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ fontSize: "small" }}
                >
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Soluta consequuntur nulla expedita architecto eum, adipisci
                  inventore natus neque necessitatibus officiis animi distinctio
                  totam optio doloremque, maxime a beatae at culpa vel corrupti
                  exercitationem autem fuga iusto quia!.
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <img
              width={60}
              style={{ marginBottom: "1rem" }}
              src={intouchLogo}
              alt="Logo"
            />

            <form className="loginForm">
              <h2>Sign In</h2>
              <div className="formGroup">
                <label className="formLabel">Email</label>
                <input
                  placeholder="Enter your email here..."
                  className="formInput"
                  type="email"
                />
              </div>
              <div className="formGroup">
                <label className="formLabel">Password</label>
                <input
                  placeholder="Enter your password here..."
                  className="formInput"
                  type="password"
                />
              </div>
              <input className="formBtn" type="submit" value="Sign Up" />
            </form>
            <div
              style={{
                height: "1px",
                background: "#d8dae5ab",
                width: "100%",
                margin: "8px 0px",
              }}
            ></div>
            <div>
              <GoogleOAuthProvider clientId={CLIENT_ID}>
                <GoogleLogin
                  onSuccess={(credentialResponse) => {
                    const encoded =
                      typeof credentialResponse.credential == "string"
                        ? credentialResponse.credential
                        : "";
                    handleLogin(jwtDecode(encoded));
                  }}
                  onError={() => {
                    console.log("Login Failed");
                  }}
                  text="continue_with"
                  theme="outline"
                  width={0}
                />
              </GoogleOAuthProvider>
            </div>
          </div>
        </Box>
      </Modal> */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10000 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Login;
