import {
  Box,
  Grid,
  Typography,
  useTheme,
  Paper,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import twitterImg from "../../assets/svg/twitter-icon.svg";
import instaImg from "../../assets/svg/instagram-icon.svg";
import linkedInImg from "../../assets/svg/linkedin-icon.svg";
import facebookImg from "../../assets/svg/facebook-icon.svg";
import youtubeImg from "../../assets/svg/youtube-icon.svg";
import snapchatImg from "../../assets/svg/snapchat-icon.svg";
import TikTokLogo from "../../assets/svg/tiktok-icon.svg";
import TwitchLogo from "../../assets/svg/twitch-icon.svg";
import YelpLogo from "../../assets/svg/yelp-icon.svg";

import { useMemo, useState } from "react";
import AppTile from "./AppTile";
import { ExpandMore } from "@mui/icons-material";

interface ILink {
  label: string;
  value: string;
  name: string;
}
interface ISocialCardProps {
  links: ILink[];
  handleClick: (link: ILink, cardName: string) => void;
}
const SocialCard = (props: ISocialCardProps) => {
  const { links, handleClick } = props;
  const theme = useTheme();
  const [activeSlide, setActiveSlide] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);

  const imgObj: any = {
    twitter: twitterImg,
    instagram: instaImg,
    linkedIn: linkedInImg,
    facebook: facebookImg,
    youTube: youtubeImg,
    snapChat: snapchatImg,
    tikTok: TikTokLogo,
    twitch: TwitchLogo,
    yelp: YelpLogo,
  };
  const linkPerSlide = useMemo(() => 6, []);
  const startIndex = useMemo(
    () => activeSlide * linkPerSlide,
    [activeSlide, linkPerSlide]
  );
  const endIndex = useMemo(
    () => activeSlide * linkPerSlide + linkPerSlide,
    [activeSlide, linkPerSlide]
  );
  const dotsCount = useMemo(
    () => Math.ceil(links.length / linkPerSlide),
    [links.length, linkPerSlide]
  );
  return (
    <>
      <div
        className="accodianSm"
        style={{ width: "100%", margin: "15px 0px 0px 15px" }}
      >
        <Accordion
          onChange={() => {
            setIsExpanded(!isExpanded);
          }}
          sx={{ boxShadow: "none", width: "100%" }}
          expanded={isExpanded}
        >
          <AccordionSummary expandIcon={<ExpandMore />} sx={{ px: 2 }}>
            <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
              Social
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <Grid item xs={12} md={6}>
              <Paper elevation={0}>
                <Box
                  sx={{
                    pt: 4,
                    px: 4,
                    pb: 1,
                    [theme.breakpoints.up("md")]: {
                      height: "40vh",
                      overflowY: "auto",
                    },
                  }}
                >
                  <Grid container spacing={2}>
                    {links
                      .slice(startIndex, endIndex)
                      .map((link: ILink, index: number) => {
                        return (
                          <Grid item xs={4} key={index}>
                            <AppTile
                              src={imgObj[link.label]}
                              link={link}
                              handleClick={(l) => handleClick(l, "social")}
                            />
                          </Grid>
                        );
                      })}
                  </Grid>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    gap: 1,
                    py: 1,
                    height: "4vh",
                  }}
                >
                  {links.length > 6 ? (
                    <>
                      {Array.from({ length: dotsCount }, (_, index) => (
                        <Button
                          key={index}
                          sx={{
                            width: "0.7rem",
                            height: "0.7rem",
                            borderRadius: "50%",
                            cursor: "pointer",
                            p: 0,
                            minWidth: "0.7rem",
                            backgroundColor: `${
                              activeSlide === index ? "#FCD463" : "#F2F2F2"
                            }`,
                          }}
                          onClick={() => setActiveSlide(index)}
                        ></Button>
                      ))}
                    </>
                  ) : null}
                </Box>
              </Paper>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>

      <Grid className="accodianLg" item xs={12} md={6}>
        <Paper elevation={0}>
          <Box
            sx={{
              width: "100%",
              p: 1,
              borderBottom: `1px solid ${theme.palette.secondary.main}`,
            }}
          >
            <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
              Social
            </Typography>
          </Box>
          <Box
            sx={{
              pt: 4,
              px: 4,
              pb: 1,
              [theme.breakpoints.up("md")]: {
                height: "40vh",
                overflowY: "auto",
              },
            }}
          >
            <Grid container spacing={2}>
              {links
                .slice(startIndex, endIndex)
                .map((link: ILink, index: number) => {
                  return (
                    <Grid item xs={4} key={index}>
                      <AppTile
                        src={imgObj[link.label]}
                        link={link}
                        handleClick={(l) => handleClick(l, "social")}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              gap: 1,
              py: 1,
              height: "4vh",
            }}
          >
            {links.length > 6 ? (
              <>
                {Array.from({ length: dotsCount }, (_, index) => (
                  <Button
                    key={index}
                    sx={{
                      width: "0.7rem",
                      height: "0.7rem",
                      borderRadius: "50%",
                      cursor: "pointer",
                      p: 0,
                      minWidth: "0.7rem",
                      backgroundColor: `${
                        activeSlide === index ? "#FCD463" : "#F2F2F2"
                      }`,
                    }}
                    onClick={() => setActiveSlide(index)}
                  ></Button>
                ))}
              </>
            ) : null}
          </Box>
        </Paper>
      </Grid>
    </>
  );
};

export default SocialCard;
