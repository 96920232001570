function EyeIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.833252 10C0.833252 10 4.16658 3.33337 9.99992 3.33337C15.8333 3.33337 19.1666 10 19.1666 10C19.1666 10 15.8333 16.6667 9.99992 16.6667C4.16658 16.6667 0.833252 10 0.833252 10Z"
        stroke="black"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99992 12.5C11.3806 12.5 12.4999 11.3808 12.4999 10C12.4999 8.61933 11.3806 7.50004 9.99992 7.50004C8.61921 7.50004 7.49992 8.61933 7.49992 10C7.49992 11.3808 8.61921 12.5 9.99992 12.5Z"
        stroke="black"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default EyeIcon;
