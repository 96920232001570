import {
  IImageList,
  IUserDetails,
} from "../../../../pages/Home/homepage.interface";
import DefaultCoverImage from "../../../../assets/svg/coverImageTwo.svg";
import PoweredByImage from "../../../../assets/svg/poweredByImage.svg";
import DefaultProfile from "../../../../assets/icons/defaultAvatar.svg";
import DefaultCompanyLogo from "../../../../assets/svg/intouch-logo.svg";
import PhoneOutlineIcon from "../../../../assets/icons/phoneOutline.svg";
import EmailOutlineIcon from "../../../../assets/icons/mailOutline.svg";
import "./templateTwo.scss";
import { Box, Typography } from "@mui/material";
import { theme } from "../../../../styles/themes";
import SocialMediaList from "../../SocialmediaList/SocialMediaList";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";

interface IProps {
  details?: IUserDetails | any;
  images?: IImageList | any;
  isLoading?: boolean;
}

function TemplateTwo({ details, images }: IProps) {
  const personalDetails = details?.personal;
  const businessDetails = details?.business;
  const generalDetails = details?.general;
  const smartCardDetails = details?.smartCardSitting;

  return (
    <Box className="templateTwo">
      <Box className="templateTwo-cover">
        {images && images.coverPhoto && images.coverPhoto.imageStream ? (
          <img
            src={`data:${images.coverPhoto.imageType};base64,${images.coverPhoto.imageStream}`}
            alt="cover-image"
            className={`templateTwo-cover-default`}
          />
        ) : (
          <>
            <img
              src={DefaultCoverImage}
              alt="cover-image"
              className={`templateTwo-cover-default`}
            />
            <img
              src={PoweredByImage}
              alt="intouch"
              className="templateTwo-cover-poweredBy"
            />
          </>
        )}
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          padding: "20px 15px 0 15px",
        }}
      >
        <Box className="templateTwo-content">
          {/* Profile Details - profile image and company logo */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <Box
              sx={{
                width: "6.2rem",
                height: "6.2rem",
                borderRadius: "50%",
                backgroundColor: "rgba(255, 255, 255, 0.35)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
              }}
            >
              {images &&
              images.profilePicture &&
              Object.values(images.profilePicture).filter((val) => val === "")
                .length === 0 ? (
                <img
                  src={`data:${images.profilePicture.imageType};base64,${images.profilePicture.imageStream}`}
                  alt="avatar"
                  className="templateTwo-content-profile"
                />
              ) : (
                <img
                  src={DefaultProfile}
                  alt="avatar"
                  className="templateTwo-content-profile"
                />
              )}
              {smartCardDetails && smartCardDetails.isProfileVerified && (
                <Box
                  sx={{
                    width: "1.5rem",
                    height: "1.5rem",
                    backgroundColor: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    position: "absolute",
                    bottom: "5px",
                    right: "5px",
                  }}
                >
                  <CheckCircleOutlinedIcon sx={{ color: "blue" }} />
                </Box>
              )}
            </Box>
            <Box sx={{ width: "3rem", height: "3rem" }}>
              {images &&
              images.companyLogo &&
              Object.values(images.companyLogo).filter((val) => val === "")
                .length === 0 ? (
                <img
                  src={`data:${images.companyLogo.imageType};base64,${images.companyLogo.imageStream}`}
                  alt="companyLogo"
                  className="templateTwo-content-company"
                />
              ) : (
                <img
                  src={DefaultCompanyLogo}
                  alt="companyLogo"
                  className="templateTwo-content-company"
                />
              )}
            </Box>
          </Box>
          {/* Profile Details - Name and company name */}
          <Box sx={{ marginBottom: "10px" }}>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "700",
                color: theme.intouchText?.primary,
              }}
            >{`${personalDetails.firstName} ${personalDetails.lastName}`}</Typography>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "500",
                color: theme.intouchText?.primary,
              }}
            >
              {businessDetails.jobTitle} at {businessDetails.businessName}
            </Typography>
            <Typography
              sx={{ fontSize: "12px", color: theme.intouchText?.gray }}
            >
              Piano, TX 75024
            </Typography>
          </Box>
          {/* Profile Details - mail and phone number and about */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginBottom: "5px",
            }}
          >
            <img
              src={PhoneOutlineIcon}
              alt="phoneLogo"
              className="templateTwo-content-phoneOutline"
            />
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "600",
                color: theme.intouchText?.primary,
              }}
            >
              {generalDetails?.phones &&
                Array.isArray(generalDetails?.phones) &&
                generalDetails?.phones[0].value}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginBottom: "5px",
            }}
          >
            <img
              src={EmailOutlineIcon}
              alt="emailLogo"
              className="templateTwo-content-phoneOutline"
            />
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "600",
                color: theme.intouchText?.primary,
              }}
            >
              {generalDetails?.emails &&
                Array.isArray(generalDetails?.emails) &&
                generalDetails?.emails[0].value}
            </Typography>
          </Box>
          <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
            {businessDetails?.about}
          </Typography>
          {/* Available Social media List */}
          <Box
            sx={{
              width: "100%",
              marginTop: "20px",
              height: "12.6rem",
              overflowY: "auto",
              paddingLeft: "23px",
            }}
            className="templateTwo-content-mediaList"
          >
            <SocialMediaList details={details} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default TemplateTwo;
