import {
  Avatar,
  Box,
  Button,
  IconButton,
  Modal,
  Paper,
  Slider,
  Typography,
  useTheme,
} from "@mui/material";
import "./imageEditorModal.scss";
import { CloseRounded } from "@mui/icons-material";
import { textCapitalize } from "../../../utils/helperFunctions";
import { IImageList } from "../../../pages/Home/homepage.interface";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";

interface Iprops {
  showModal: boolean;
  onClose: () => void;
  modalHeader: string;
  imageDatas: IImageList | undefined;
  modifiedImageUrl: string;
  setModifiedImageUrl: (e: any) => void;
  setModifiedImageData: (e: any) => void;
  handleUpload: () => void;
}

function ImageEditorModal({
  showModal,
  onClose,
  modalHeader,
  imageDatas,
  modifiedImageUrl,
  setModifiedImageUrl,
  setModifiedImageData,
  handleUpload,
}: Iprops) {
  const initialSliderValue = 10;
  const [file, setFile] = useState<any>(null);
  const [showEditor, setShowEditor] = useState(false);
  const [slideValue, setSlideValue] = useState(initialSliderValue);
  const fileUploadRef = useRef<any>();
  const avatarEditorRef = useRef<any>();
  const theme = useTheme();
  let type: any = modalHeader === "profile image" ? "profileImage" : "";
  const profileImage: any = imageDatas && imageDatas?.profilePicture;
  const companyImage: any = imageDatas && imageDatas?.companyLogo;
  const coverImage: any = imageDatas && imageDatas?.coverPhoto;

  const avatarEditorStyleObj =
    modalHeader === "profile image"
      ? { width: "10rem", height: "10rem" }
      : modalHeader === "company image"
      ? { width: "100px", height: "100px" }
      : modalHeader === "cover image"
      ? { width: "17rem", height: "7rem" }
      : {};

  const previewImageStyleObj: any =
    modalHeader === "profile image"
      ? {
          width: "100%",
          height: "100%",
          borderRadius: "50%",
          objectFit: "cover",
        }
      : modalHeader === "company image" || modalHeader === "cover image"
      ? { width: "100%", height: "100%" }
      : {};

  const handleOpenFile = () => {
    fileUploadRef.current.click();
  };

  const handleFileChange = (e: any) => {
    setFile(URL.createObjectURL(e.target.files[0]));
    setShowEditor(true);
  };

  const handleSave = async () => {
    if (avatarEditorRef) {
      const dataUrl = avatarEditorRef.current.getImage().toDataURL();
      const result = await fetch(dataUrl);
      const blob = await result.blob();
      const imageType = dataUrl.split(":")[1].split(";")[0];
      const imageStream = dataUrl.split(":")[1].split(";")[1].split(",")[1];
      setModifiedImageData({ imageType, imageStream });
      setModifiedImageUrl(URL.createObjectURL(blob));
      setShowEditor(false);
    }
  };

  const handleCloseModal = () => {
    setFile(null);
    setSlideValue(initialSliderValue);
    setModifiedImageUrl("");
    setShowEditor(false);
    onClose();
  };

  const updateImage = () => {
    handleUpload();
    handleCloseModal();
  };

  return (
    <Modal
      open={showModal}
      onClose={handleCloseModal}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      className="editorModal"
    >
      <Box
        sx={{
          borderRadius: "0.25rem",
          p: 2,
          [theme.breakpoints.up("md")]: { width: "35vw" },
          width: "70vw",
        }}
        component={Paper}
        elevation={0}
        className="editorModal-container"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "1rem",
              textTransform: "capitalize",
            }}
          >
            Edit {textCapitalize(modalHeader)}
          </Typography>
          <CloseRounded
            sx={{ color: "#8F95B2", cursor: "pointer" }}
            onClick={handleCloseModal}
          />
        </Box>
        <div className="editorModal-container-content">
          <div className="editorModal-container-content-editorWrapper">
            {!showEditor ? (
              <div
                className={`editorModal-container-content-editorWrapper-preview-${
                  modalHeader === "profile image"
                    ? "profile"
                    : modalHeader === "company image"
                    ? "company"
                    : modalHeader === "cover image"
                    ? "cover"
                    : ""
                }`}
              >
                {modifiedImageUrl && modalHeader === "profile image" ? (
                  <img
                    src={modifiedImageUrl}
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                ) : modifiedImageUrl && modalHeader === "company image" ? (
                  <img
                    src={modifiedImageUrl}
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                ) : modifiedImageUrl && modalHeader === "cover image" ? (
                  <img
                    src={modifiedImageUrl}
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                ) : (modalHeader === "profile image" &&
                    profileImage?.imageType) ||
                  (modalHeader === "company image" &&
                    companyImage?.imageType) ||
                  (modalHeader === "cover image" && coverImage?.imageType) ? (
                  <img
                    src={
                      modalHeader === "profile image"
                        ? `data:${profileImage?.imageType};base64,${profileImage?.imageStream}`
                        : modalHeader === "company image"
                        ? `data:${companyImage?.imageType};base64,${companyImage?.imageStream}`
                        : modalHeader === "cover image"
                        ? `data:${coverImage?.imageType};base64,${coverImage?.imageStream}`
                        : ""
                    }
                    style={previewImageStyleObj}
                  />
                ) : (
                  <div
                    className={`editorModal-container-content-editorWrapper-preview-defaultImage-${
                      modalHeader === "profile image"
                        ? "profile"
                        : modalHeader === "company image"
                        ? "company"
                        : modalHeader === "cover image"
                        ? "cover"
                        : ""
                    }`}
                  >
                    <Avatar alt="" src="/static/images/avatar/1.jpg" />
                  </div>
                )}
              </div>
            ) : (
              <div className="editorModal-container-content-editorWrapper-editorcontainer">
                <AvatarEditor
                  ref={avatarEditorRef}
                  image={file}
                  style={avatarEditorStyleObj}
                  borderRadius={
                    modalHeader === "profile image"
                      ? 150
                      : modalHeader === "company image"
                      ? 10
                      : 0
                  }
                  scale={slideValue / 10}
                />
                <Slider
                  sx={{
                    margin: "20px 0",
                    width: "80%",
                    color: "cyan",
                  }}
                  min={10}
                  max={50}
                  size="medium"
                  defaultValue={slideValue}
                  value={slideValue}
                  onChange={(e: any) => setSlideValue(e.target.value)}
                />
              </div>
            )}
            <div>
              {!showEditor && (
                <IconButton onClick={handleOpenFile} className="cursor-pointer">
                  <AddCircleOutlineIcon />
                </IconButton>
              )}
              <input
                type="file"
                ref={fileUploadRef}
                className="hide-field"
                onChange={handleFileChange}
              />
            </div>
          </div>
        </div>
        {!showEditor ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              justifyContent: "end",
            }}
          >
            <Button
              size="small"
              sx={{ color: "#696F8C" }}
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              size="small"
              sx={{
                color: theme.palette.warning.contrastText,
                bgcolor: theme.palette.warning.main,
              }}
              onClick={updateImage}
              disabled={!modifiedImageUrl}
            >
              Save
            </Button>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              size="small"
              sx={{
                color: theme.palette.warning.contrastText,
                bgcolor: theme.palette.warning.main,
              }}
              onClick={handleSave}
            >
              Done
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
}

export default ImageEditorModal;
