import {
  Box,
  Grid,
  Typography,
  useTheme,
  Paper,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import emailImg from "../../assets/svg/outlook-icon.svg";
import phoneImg from "../../assets/svg/phonecall-icon.svg";
import linkImg from "../../assets/svg/phone-icon.svg";
import mapImg from "../../assets/images/maps.png";
import { Fragment, useMemo, useState } from "react";
import AppTile from "./AppTile";
import { ExpandMore } from "@mui/icons-material";
import "./Home.css";

interface ILink {
  label: string;
  value: string;
  name: string;
}
interface IGeneralardProps {
  links: ILink[];
  handleClick: (link: ILink, cardName: string) => void;
}
const GeneralCard = (props: IGeneralardProps) => {
  const { links, handleClick } = props;
  const theme = useTheme();
  const [activeSlide, setActiveSlide] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);

  const imgObj: any = {
    emails: emailImg,
    phones: phoneImg,
    urls: linkImg,
    links: linkImg,
    About: mapImg,
  };
  const linkPerSlide = useMemo(() => 6, []);
  const startIndex = useMemo(
    () => activeSlide * linkPerSlide,
    [activeSlide, linkPerSlide]
  );
  const endIndex = useMemo(
    () => activeSlide * linkPerSlide + linkPerSlide,
    [activeSlide, linkPerSlide]
  );
  const dotsCount = useMemo(
    () => Math.ceil(links.length / linkPerSlide),
    [links.length, linkPerSlide]
  );

  return (
    <>
      <div
        className="accodianSm"
        style={{ width: "100%", margin: "15px 0px 0px 15px" }}
      >
        <Accordion
          onChange={() => {
            setIsExpanded(!isExpanded);
          }}
          sx={{ boxShadow: "none", width: "100%" }}
          expanded={isExpanded}
        >
          <AccordionSummary expandIcon={<ExpandMore />} sx={{ px: 2 }}>
            <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
              General
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <Fragment>
              <Grid item sx={{ width: "100%" }} xs={12} md={6}>
                <Paper elevation={0}>
                  <Box
                    sx={{
                      pt: 4,
                      px: 4,
                      pb: 1,
                      [theme.breakpoints.up("md")]: {
                        height: "40vh",
                        overflowY: "auto",
                      },
                    }}
                  >
                    <Grid container spacing={2}>
                      {links
                        .slice(startIndex, endIndex)
                        .map((link: ILink, index: number) => {
                          return (
                            <Grid item xs={4} key={index}>
                              <AppTile
                                src={imgObj[link.label]}
                                link={link}
                                handleClick={(l) => {
                                  handleClick(l, "general");
                                }}
                              />
                            </Grid>
                          );
                        })}
                    </Grid>
                  </Box>
                  <Box
                    sx={{
                      [theme.breakpoints.up("md")]: {
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        gap: 1,
                        py: 1,
                        height: "4vh",
                      },
                      display: "none",
                    }}
                  >
                    {links.length > 6 ? (
                      <>
                        {Array.from({ length: dotsCount }, (_, index) => (
                          <Button
                            sx={{
                              width: "0.7rem",
                              height: "0.7rem",
                              borderRadius: "50%",
                              cursor: "pointer",
                              p: 0,
                              minWidth: "0.7rem",
                              backgroundColor: `${
                                activeSlide === index ? "#FCD463" : "#F2F2F2"
                              }`,
                            }}
                            onClick={() => setActiveSlide(index)}
                          ></Button>
                        ))}
                      </>
                    ) : null}
                  </Box>
                </Paper>
              </Grid>
            </Fragment>
          </AccordionDetails>
        </Accordion>
      </div>
      <Fragment>
        <Grid className="accodianLg" item xs={12} md={6}>
          <Paper elevation={0}>
            <Box
              sx={{
                width: "100%",
                p: 1,
                borderBottom: `1px solid ${theme.palette.secondary.main}`,
              }}
            >
              <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                General
              </Typography>
            </Box>
            <Box
              sx={{
                pt: 4,
                px: 4,
                pb: 1,
                [theme.breakpoints.up("md")]: {
                  height: "40vh",
                  overflowY: "auto",
                },
              }}
            >
              <Grid container spacing={2}>
                {links
                  .slice(startIndex, endIndex)
                  .map((link: ILink, index: number) => {
                    return (
                      <Grid item xs={4} key={index}>
                        <AppTile
                          src={imgObj[link.label]}
                          link={link}
                          handleClick={(l) => {
                            handleClick(l, "general");
                          }}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </Box>
            <Box
              sx={{
                [theme.breakpoints.up("md")]: {
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  gap: 1,
                  py: 1,
                  height: "4vh",
                },
                display: "none",
              }}
            >
              {links.length > 6 ? (
                <>
                  {Array.from({ length: dotsCount }, (_, index) => (
                    <Button
                      sx={{
                        width: "0.7rem",
                        height: "0.7rem",
                        borderRadius: "50%",
                        cursor: "pointer",
                        p: 0,
                        minWidth: "0.7rem",
                        backgroundColor: `${
                          activeSlide === index ? "#FCD463" : "#F2F2F2"
                        }`,
                      }}
                      onClick={() => setActiveSlide(index)}
                    ></Button>
                  ))}
                </>
              ) : null}
            </Box>
          </Paper>
        </Grid>
      </Fragment>
      {/* </div> */}
    </>
  );
};

export default GeneralCard;
