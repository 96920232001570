import { Button, Grid, useTheme } from "@mui/material";
import "./mobilePreviewScreen.scss";
import SettingsIcon from "../../assets/icons/settings.svg";
import EyeIcon from "../../assets/icons/EyeIcon";
import GridIcon from "../../assets/icons/GridIcon";
import { useState } from "react";
import TemplateOne from "../Common/Templates/TemplateOne/TemplateOne";
import { IImageList, IUserDetails } from "../../pages/Home/homepage.interface";
import TemplateTwo from "../Common/Templates/TemplateTwo/TemplateTwo";

interface IProps {
  userDetail: IUserDetails;
  imageList: IImageList;
  setShowTemplateModal: (e: any) => void;
  isLoading: boolean;
  selectedTemplate: any;
  setShowDevelopmentModal: (e: any) => void;
}

function MobilePreviewScreen({
  userDetail,
  imageList,
  setShowTemplateModal,
  isLoading,
  selectedTemplate,
  setShowDevelopmentModal,
}: IProps) {
  const theme = useTheme();
  const templateObj: any = {
    Basic01: (
      <TemplateOne
        details={userDetail}
        images={imageList}
        isLoading={isLoading}
      />
    ),
    Basic02: (
      <TemplateTwo
        details={userDetail}
        images={imageList}
        isLoading={isLoading}
      />
    ),
    Basic03: (
      <TemplateOne
        details={userDetail}
        images={imageList}
        isLoading={isLoading}
      />
    ),
  };

  const [selectedView, setSelectedView] = useState("eye");
  return (
    <main className="container">
      <Grid item xl={10} lg={10} xs={12} className="container-mobile">
        <header className="container-mobile-header">
          <div className="container-mobile-header-close"></div>
          <div className="container-mobile-header-minimize"></div>
          <div className="container-mobile-header-expand"></div>
        </header>
        <section>{templateObj[selectedTemplate]}</section>
      </Grid>
      <Grid className="container-footer">
        <div className="container-footer-left">
          <p className="container-footer-left-themeName">{selectedTemplate}</p>
          <img
            src={SettingsIcon}
            alt="settingsImage"
            onClick={() => setShowDevelopmentModal(true)}
            style={{ display: "none" }}
          />
        </div>
        <div className="container-footer-right">
          <div
            className={`container-footer-right-wrapper${
              selectedView === "eye" ? "--selected" : ""
            }`}
            onClick={() => setSelectedView("eye")}
            style={{ display: "none" }}
          >
            <EyeIcon />
          </div>
          <div
            className={`container-footer-right-wrapper${
              selectedView === "grid" ? "--selected" : ""
            }`}
            onClick={() => setShowDevelopmentModal(true)}
            style={{ display: "none" }}
          >
            <GridIcon />
          </div>
          <Button
            size="small"
            sx={{
              color: theme.palette.warning.contrastText,
              bgcolor: theme.palette.warning.main,
              padding: "5px 15px",
            }}
            onClick={() => setShowTemplateModal(true)}
          >
            Set Template
          </Button>
        </div>
      </Grid>
    </main>
  );
}

export default MobilePreviewScreen;
