import { Box, Modal, Paper, Typography, useTheme } from "@mui/material";
import CoverImage from "../../../assets/svg/animatedMen.svg";

interface IProps {
  showModal: boolean;
  handleCloseModal: () => void;
}

function UnderDevelopmentPopup({ showModal, handleCloseModal }: IProps) {
  const theme = useTheme();
  return (
    <Modal
      open={showModal}
      onClose={handleCloseModal}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          borderRadius: "0.5rem",
          width: "25rem",
          outline: "none",
        }}
        component={Paper}
        elevation={0}
        className="templateModal"
      >
        <Box sx={{ width: "100%", height: "15rem" }}>
          <img
            src={CoverImage}
            alt="coverImage"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderTopLeftRadius: ".5rem",
              borderTopRightRadius: ".5rem",
            }}
          />
        </Box>
        <Box sx={{ padding: "20px" }}>
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "700",
              color: theme.intouchText?.primary,
            }}
          >
            Hey There!
          </Typography>
          <Typography sx={{ fontSize: "14px", marginBottom: "20px" }}>
            We're thrilled to share some exciting news with you!
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "700",
              color: theme.intouchText?.primary,
            }}
          >
            Our Team is Hard at Work!
          </Typography>
          <Typography sx={{ fontSize: "14px" }}>
            We're currently working on a fantastic new feature to enhance your
            experience. Stay Tuned!
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
}

export default UnderDevelopmentPopup;
